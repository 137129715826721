import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { useSelector } from 'react-redux';

const Loader = () => {
  const { isLoading } = useSelector((state) => state.loader);
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
      <div className="loader-conatiner">
        {/* <div class="loader">
         <div style={{ '--i': 1 }}></div>
         <div style={{ '--i': 2 }}></div>
         <div style={{ '--i': 3 }}></div>
         <div style={{ '--i': 4 }}></div>
       </div> */}
        <h1>Loading...</h1>
        <span className="loader"></span>
      </div>
    </Backdrop>
  );
};

export default Loader;
