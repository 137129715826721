import dayjs from 'dayjs';
import Swal from 'sweetalert2';

export const convertDateIntoDDMMYYYY = (date) => {
  return dayjs(date).locale('en').format('DD-MM-YYYY');
};
export const convertDateIntoYYYYMMDD = (date) => {
  return dayjs(date).locale('en').format('YYYY-MM-DD');
};

//  USER----------------------------

export const handleNextSlide = (SliderRef) => {
  if (SliderRef.current) {
    SliderRef.current.slickNext();
  }
};

export const handlePrevSlide = (SliderRef) => {
  if (SliderRef.current) {
    SliderRef.current.slickPrev();
  }
};

// ADMIN----------------------------

export const MESSAGE_ICON = {
  success: 'success',
  error: 'error',
  warning: 'warning'
};

export const MESSAGE_TITLE = {
  success: 'Success !',
  error: 'Error !',
  warning: 'Warning !',
  delete: 'Deleted !'
};

export const ShowMessage = (icon, title, message) => {
  Swal.fire({
    title: title,
    text: message,
    icon: icon,
    showConfirmButton: false,
    confirmButtonText: 'Ok',
    timer: 1800
  });
};

export const ConfirmationAlert = () => {
  return Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#81a649',
    cancelButtonColor: '#172432',
    confirmButtonText: 'Yes, delete it!'
  });
};

export const getRanges = () => {
  const today = new Date();
  const lastMonthStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);

  const thisMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1);

  const lastWeekStartDate = new Date(today.getTime() - (today.getDay() + 7) * 24 * 60 * 60 * 1000);
  const lastWeekEndDate = new Date(today.getTime() - (today.getDay() + 1) * 24 * 60 * 60 * 1000);

  const firstDayOfWeek = today.getDate() - today.getDay();
  const thisWeekStartDate = new Date(today.setDate(firstDayOfWeek));
  const thisWeekEndDate = new Date();

  const ranges = [
    {
      label: 'Today',
      value: [new Date(), new Date()]
    },
    {
      label: 'This week',
      value: [thisWeekStartDate, thisWeekEndDate]
    },
    {
      label: 'Last week',
      value: [lastWeekStartDate, lastWeekEndDate]
    },
    {
      label: 'Last 7 Days',
      value: [new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000), new Date()]
    },
    {
      label: 'This Month',
      value: [thisMonthStartDate, new Date()]
    },
    {
      label: 'Last Month',
      value: [lastMonthStartDate, lastMonthEndDate]
    },
    {
      label: 'Last 30 Days',
      value: [new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000), new Date()]
    }
  ];

  return ranges;
};
