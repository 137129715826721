import React, { useRef } from 'react';
import Container from '../../../components/Container';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import Slider from 'react-slick';
import { popular_destinations_data } from '../../../utils/data';
import { handleNextSlide, handlePrevSlide } from '../../../utils/helper';
import { detailsTag } from '../../../utils/constants';

const PopularDestinationSection = ({ openDetailModalHandler }) => {
  const popularSliderRef = useRef(null);

  const popular_settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    ref: popularSliderRef,

    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 412,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <>
      {/* Popular Destinations In India section start */}
      <Container>
        <div
          id="packages"
          className="py-[32px] sm:py-[48px] md:py-[52px] lg:py-[60px] xl:py-[60px] px-0"
        >
          <div className="flex flex-row gap-[16px] justify-between items-center">
            <h1 className="text-primary">Popular Destinations In India</h1>
            <div className="slider-action">
              <div className="btn-prev" onClick={handlePrevSlide.bind(null, popularSliderRef)}>
                <IoChevronBack />
              </div>
              <div className="btn-next" onClick={handleNextSlide.bind(null, popularSliderRef)}>
                <IoChevronForward />
              </div>
            </div>
          </div>
          <div className="w-full mb-0 mt-[32px] mx-auto overflow-x-hidden">
            <Slider {...popular_settings} className="destination-slider">
              {popular_destinations_data.map((item, index) => {
                return (
                  <div
                    key={item.id + '_popular'}
                    // className="h-[550px] cursor-pointer package"
                    className="h-[540px] border border-[#D0D4CA] cursor-pointer px-[10px] py-[10px] rounded-lg shadow-xl package-2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    data-aos-easing="linear"
                    data-aos-duration="500"
                    onClick={openDetailModalHandler.bind(
                      null,
                      detailsTag.TRIP_TAG,
                      item.details,
                      `Popular.${item.place}`
                    )}
                  >
                    <div className="w-full h-[45%] md:h-[45%]">
                      <img
                        src={item.image}
                        alt={`Slide ${index + 1}`}
                        // className="w-full sm:w-[90%] h-[45%] md:h-[45%]"
                        className="w-full h-full"
                        // style={{
                        //   borderTopLeftRadius: '10px',
                        //   borderTopRightRadius: '10px',
                        //   boxShadow:
                        //     'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                        // }}
                        style={{
                          borderRadius: '6px'
                        }}
                      />
                    </div>
                    <div
                      // className="px-[18px] py-[10px] w-full sm:w-[90%]"
                      className="w-full sm:w-full px-[8px]"
                      // style={{
                      //   borderBottomLeftRadius: '10px',
                      //   borderBottomRightRadius: '10px',
                      //   boxShadow:
                      //     'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                      // }}
                    >
                      <h4 className="text-[20px] lg:text-[26px] xl:text-[28px] text-color-black mt-1">
                        {item.place}
                      </h4>
                      <p className="text-justify text-color-grey mt-[8px] h-[180px] overflow-scroll text-[16px]">
                        {item.description}
                      </p>
                      <div className="mt-[16px] flex flex-row justify-between gap-[26px]">
                        <p className="flex flex-row justify-center items-center gap-[10px]">
                          <span className="text-color-grey font-normal">From</span>{' '}
                          <span className="text-color-green text-[22px] font-semibold tracking-wider">
                            ₹{item.price}
                            {' *'}
                          </span>
                        </p>
                        <div className="text-color-black flex flex-row justify-center items-center gap-[6px]">
                          {/* <p className="font-semibold">DURATION</p> */}
                          <p className="text-[20px]">
                            <span className="text-color-black font-bold">{item.duration.day}</span>{' '}
                            <span className="text-color-grey">D</span>
                          </p>
                          <p>/</p>
                          <p className="text-[20px]">
                            <span className="text-color-black font-bold">
                              {item.duration.night}
                            </span>{' '}
                            <span className="text-color-grey">N</span>
                          </p>
                        </div>

                        {/* <div
                          className="bg-[#81A649] w-max rounded-[6px] md:rounded-[8px]
                          py-[8px] px-[12px] md:py-[10px] md:px-[14px]
                          flex flex-row justify-center items-center tracking-wide"
                        >
                          <a
                            href="/"
                            rel="noreferrer"
                            target="_blank"
                            className="text-white text-[16px] md:text-[16px]"
                          >
                            Details
                          </a>
                        </div> */}
                      </div>
                    </div>

                    {/* <div className="overlay"></div> */}
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </Container>
      {/* Popular Destinations In India section end */}
    </>
  );
};

export default PopularDestinationSection;
