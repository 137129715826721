import React from 'react';
import UserPage from './pages/User';
import AOS from 'aos';
import { Navigate, Route, Routes } from 'react-router-dom';

import './assets/styles/global.scss';

import LayoutProvider from './layout';
import Dashboard from './pages/Admin/Dashboard';
import Inquiry from './pages/Admin/Inquiry';
import Login from './pages/Login';

import 'aos/dist/aos.css';

import { getAuthToken } from './utils/auth';
import ProtectedRoute from './components/common/ProtectedRoute';
import Loader from './components/common/Loader';

const token = getAuthToken();

const App = () => {
  AOS.init();
  return (
    <>
      <Loader />

      <Routes>
        <Route path="/" element={<UserPage />} />
        <Route path="/login" element={!token ? <Login /> : <Navigate to="/admin" replace />} />
        <Route path="/admin" element={<LayoutProvider />}>
          <Route
            index
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="inquiry"
            element={
              <ProtectedRoute>
                <Inquiry />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default App;
