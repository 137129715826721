import axios from 'axios';
import { getAuthToken, logoutHandler } from '../utils/auth';
import { MESSAGE_ICON, ShowMessage } from '../utils/helper';

const BASE_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' }
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (request) {
    const authToken = getAuthToken();
    request.headers.Authorization = `${authToken}`;
    return request;
  },
  function (error) {
    return error;
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    console.log('interceptor response', response);
    return response.data;
  },
  function (error) {
    console.log('interceptor error', error);
    if (error.response.data.status === 401 || error.response.data.status === 403) {
      ShowMessage(MESSAGE_ICON.warning, error.response.data.message, '');
      setTimeout(() => {
        logoutHandler();
      }, [2500]);
    }
    return error;
  }
);

export const postCall = (url, body) => {
  const result = axiosInstance
    .post(url, body)
    .then((response) => {
      // console.log('post', response);
      return response;
    })
    .catch((error) => {
      // console.log('post', error);
      return error;
    });
  return result;
};

export const getCall = (url, params = {}, id = '') => {
  const newUrl = `${url}/${id}`;
  const result = axiosInstance
    .get(newUrl, { params })
    .then((response) => {
      // console.log('get response', response);
      return response;
    })
    .catch((error) => {
      // console.log('get error', error);
      return error;
    });
  return result;
};

export const deleteCall = (url, id = '') => {
  const newUrl = `${url}/${id}`;
  const result = axiosInstance
    .delete(newUrl)
    .then((response) => {
      // console.log('delete response', response);
      return response;
    })
    .catch((error) => {
      // console.log('delete error', error);
      return error;
    });
  return result;
};
