import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SiteLogo from '../../assets/images/logo/logo-black.png';
import { useLogin } from './hook/useLogin';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Typography
        style={{ fontWeight: 'bold' }}
        component={'span'}
        variant="span"
        color="inherit"
        to={'/'}
      >
        The Gujarati Traveller
      </Typography>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Login = () => {
  const { usernameRef, formik } = useLogin();

  return (
    // <ThemeProvider theme={defaultTheme}>
    //   <Container component="main" maxWidth="xs" sx={{ overflow: 'hidden' }}>
    // <CssBaseline />

    <Box className="login-container">
      <Box className="login-box">
        <Box className="login-header">
          <img
            src={SiteLogo}
            alt="login page logo"
            width={60}
            height={60}
            style={{ marginBottom: '12px' }}
          />
          <Typography component="p" variant="p">
            Adventures Await: <br /> Sign In and Go Places
          </Typography>
        </Box>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
          <hr />
          {/* email */}
          <TextField
            ref={usernameRef}
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="email"
            autoFocus
            className={formik.touched.username && formik.errors.username ? 'field-error' : ''}
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.username && formik.errors.username ? (
            <span className="error">{formik.errors.username}</span>
          ) : null}

          {/* password */}
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            className={formik.touched.password && formik.errors.password ? 'field-error' : ''}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password && formik.errors.password ? (
            <span className="error">{formik.errors.password}</span>
          ) : null}

          {/* <br />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}

          <Button type="submit" sx={{ mt: 3, mb: 2 }} className="signin-btn">
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <Typography variant="body2" style={{ fontWeight: '700' }}>
                Forgot password?
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                Don't have an account? <b>Sign Up</b>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Box>
    </Box>
    //   </Container>
    // </ThemeProvider>
  );
};

export default Login;
