import { Fade, Modal } from '@mui/material';
import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { detailsTag } from '../utils/constants';

const TripDetailsModal = ({
  isDetailModalOpen,
  detail,
  tag,
  place,
  closeDetailModalHandler,
  openInquiryModalHandler
}) => {
  const bookNowClickHandler = () => {
    closeDetailModalHandler();
    openInquiryModalHandler(place);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isDetailModalOpen}
        onClose={closeDetailModalHandler}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
        data-aos="fade-up"
        data-aos-easing="ease-in"
        data-aos-duration="500"
      >
        <Fade in={isDetailModalOpen}>
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[90%] bg-white p-6 rounded-[6px] w-[90%] sm:w-[80%] md:w-[70%] lg:w-[65%] xl:w-[50%] overflow-scroll">
            <div
              className="absolute top-2 right-2 cursor-pointer"
              onClick={closeDetailModalHandler}
            >
              <IoMdClose className="text-color-black text-[24px]" />
            </div>
            {tag === detailsTag.TRIP_TAG && (
              <>
                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <div className="flex flex-row justify-between items-center">
                    <h3 className="text-[16px] sm:text-[18px] md:text-[22px] lg:text-[28px]">
                      {detail?.heading}
                    </h3>
                    <button
                      className="bg-color-green text-white rounded-md cursor-pointer w-max 
                      text-[14px] sm:text-[14px] md:text-[16px]
                      px-[8px] py-[6px] sm:px-[12px] sm:py-[8px] md:px-[15px] md:py-[10px] whitespace-nowrap"
                      onClick={bookNowClickHandler}
                    >
                      Book Now
                    </button>
                  </div>
                  <p className="mt-1 text-justify text-[14px] sm:text-[16px] md:text-[18px] lg:text-[22px]">
                    {detail?.subHeading}
                  </p>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px] sm:text-[18px] md:text-[20px]">Short Itinerary</h3>
                  <hr />
                  <div className="flex flex-col gap-[4px] mt-[4px] text-[12px] md:text-[14px]">
                    {detail?.short_itinerary?.map((row, index) => {
                      return <p key={index + '_short_itinerary'}>{row}</p>;
                    })}
                  </div>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px] sm:text-[18px] md:text-[20px]">Pkg. Inclusions</h3>
                  <hr />
                  <ol className="flex flex-col gap-[4px] mt-[4px] text-justify text-[12px] md:text-[14px]">
                    {detail?.inclusions?.map((row, index) => {
                      return <li key={index + '_inclusions'}>- {row}</li>;
                    })}
                  </ol>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px] sm:text-[18px] md:text-[20px]">Pkg. Exclusions</h3>
                  <hr />
                  <ol className="flex flex-col gap-[4px] mt-[4px] text-justify text-[12px] md:text-[14px]">
                    {detail?.exclusions?.map((row, index) => {
                      return <li key={index + '_exclusions'}>- {row}</li>;
                    })}
                  </ol>
                </div>
              </>
            )}

            {tag === detailsTag.TERMS_CONDITIONS_TAG && (
              <>
                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">TERMS & CONDITIONS</h3>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;This website is published and maintained by The Gujarati
                    Traveller, a company incorporated and existing in accordance with the laws of
                    the Republic of India. This page sets forth the terms and conditions under which
                    The Gujarati Traveller provides the information on this Website, as well as the
                    terms and conditions governing your use of this site. The Users availing
                    services from The Gujarati Traveller shall be a natural or legal person and
                    deemed to have read, understood and expressly accepted the terms and conditions
                    of the agreement, which alone shall govern the desired transaction or provision
                    of such service by The Gujarati Traveller for all purposes, and shall be binding
                    on the user. All rights and liabilities of the User with respect to any services
                    to be provided by The Gujarati Traveller shall be restricted to the scope of
                    this agreement. If you do not accept these terms and conditions, do not continue
                    to use or access this site, or order any searches.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;In addition to this agreement, there are certain terms
                    of service (TOS) specific to the services rendered/products provided by The
                    Gujarati Traveller like the air tickets, bus, rail, holiday packages, etc. Such
                    TOS will be provided / updated by The Gujarati Traveller which shall be deemed
                    to be a part of this Agreement and in the event of conflict between such TOS and
                    this agreement, the terms of this agreement shall prevail. The user shall be
                    required to read and accept the relevant TOS for the service/ product availed by
                    the user.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;Additionally, the Service Provider itself may provide
                    terms and guidelines that govern particular features, offers or the operating
                    rules and policies applicable to each Service (for example , flights, hotel
                    reservation, Packages , etc). The User shall be responsible for ensuring
                    compliance with the terms and guidelines or operating rules and policies of the
                    services provided with whom User elects to deal, including terms and conditions
                    set forth in a Service Providers’ fare rules, contract of carriage, cancellation
                    or other rules.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;The Gujarati Traveller’s Services are offered to the
                    User Conditional on acceptance without modification of all the terms, conditions
                    and notices contained in the agreement and the TOS, as may be applicable from
                    time to time. For the removal of doubts, it is clarified that availing of the
                    services by the User constitutes an acknowledgement and acceptance by the User
                    of the Agreement and the TOS. If the User does not agree with any part of such
                    terms, conditions and notices, the User must not avail The Gujarati Traveller’s
                    Services.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;In the event that any of the terms, conditions and
                    notices contained herein conflict with the Additional Terms and guidelines
                    contained within any other The Gujarati Traveller Document, then these terms
                    shall be applicable.
                  </p>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">EXECUTIVE SUMMARY</h3>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;You, as our client, agree to check all documentation
                    immediately for errors. Travel With A Cause (TWAC) does not take responsibility
                    for incorrect dates or names supplied.
                  </p>
                  <ul className="text-justify text-[12px]">
                    <li className="mt-1">
                      • Prices, including, in some cases, of confirmed bookings, may be subject to
                      change. ?{' '}
                    </li>
                    <li className="mt-1">
                      • Some confirmed bookings are non-refundable if cancelled by you and it is
                      your responsibility to check if this applies.
                    </li>
                    <li className="mt-1">
                      • We will be entitled to retain our service fees even if a booking is
                      cancelled or does not proceed for any reason which is not our fault.
                    </li>
                    <li className="mt-1">
                      • It is your responsibility to make yourself aware of all information relevant
                      to your travel plans, including but not limited to visa requirements and
                      health precautions.
                    </li>
                    <li className="mt-1">
                      • We are not liable for the accuracy of any published Supplier content
                      including websites and brochures.
                    </li>
                  </ul>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">PRICES AND TAXES</h3>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;The total price displayed on the Website on the payment
                    page usually includes base fare, applicable government taxes and convenience
                    fee. Users are required to pay the entire amount prior to the confirmation of
                    their bookings. In the event the User does not pay the entire amount, The
                    Gujarati Traveller reserves its right to cancel the booking. User agrees to pay
                    all statutory taxes, surcharges and fees, as applicable on the date of travel.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;Prices include all applicable taxes requiring payment
                    prior to departure, and may be subject to adjustment in the event of an increase
                    in those taxes. On other occasions, you may be liable for taxes in addition to
                    the quoted price of the Product. For example, there may be a local tax charged
                    at some airports or resorts.
                  </p>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">REFUND AND CANCELLATION FEES</h3>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;Tour Booking: In this tour, for tour booking Rs.3000/-
                    per person. (Non Refundable) has to be paid as well as full amount for air
                    ticket. Timely booking is recommended to get low priced air ticket. After that
                    you have to get tickets online. The difference will have to be paid by the
                    traveller. Deposit of final payment is mandatory 30 days prior to travel.
                    Otherwise his name, hotel and other arrangements will be cancelled and the
                    payment made will not be refunded,
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;Tour Cancellation: For cancellation of all tours, the
                    tour will be cancelled only after receiving a written request for tour
                    cancellation from the person who booked the tour. Since the group ticket of the
                    plane is non-refundable, it cannot be cancelled, so no refund will be given. If
                    the tour has to be cancelled due to any circumstances of the traveller, no
                    refund of the tour booking amount will be given, except for the additional
                    amount (a credit note with validity up to 1 year will be issued) so that the
                    traveller can re-join our other tour after some time. After 1 year the credit
                    note will be automatically cancelled and no refund will be given. If the flight
                    is cancelled or rescheduled under any circumstances, the passenger will be fully
                    responsible for the cancellation and re-booking.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;Also, 60 days before the date of departure of the
                    journey per  ticket Rs. 2000/- , 30 days before  the date of departure of the
                    journey per  ticket Rs. 3000/- will be deducted and those who cancel the booking
                    within 30 days will not get any refund and the remaining amount will be in the
                    form of credit note.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;In the event that &quot;ACT OF GOD&quot; has to cancel
                    the entire tour due to an epidemic like Corona or any other reason, Rs. 2000
                    will be given as credit net of remaining Rs. All travellers should take special
                    note of it.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;Special Note : In some cases, when the tourist book the
                    tour we also book in advance hotel booking vehicle booking good kitchen staff
                    and good tour manager to get good and timely accommodation for their family, but
                    any natural or man-made and sudden Due to unusual and difficult circumstances
                    such as floods, earthquakes, cloud bursts, landslides, political instability,
                    shutdowns, strikes, riots, terrorist attacks, rescheduling or cancellation of
                    trains or flights or due to illness, death of a relative or current epidemic
                    like Corona or In general terms, if the tour is cancelled or has to be cancelled
                    due to any other reason like “ACT OF GOD” we do not get any refund of the money
                    paid in advance as well as advertising and office expenses, so if the tour is
                    cancelled or has to be done.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;A credit note with a validity of one year will be issued
                    and no cash or refund will be given under any circumstances. Our other
                    travellers through this credit note. Anyone can join the tour.
                  </p>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">Conditions Of Passage</h3>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;These conditions are applicable to every client of the
                    company, who books a Tour or any Special tour or travel arrangement with The
                    Gujarati Traveller. Payment schedule, Cancellation, refund, etc. shall be
                    applicable, in addition to our `Terms and Conditions’.
                  </p>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">Travel Documents</h3>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;All the required travel documents should be submitted to
                    us when required. Your Passport should be valid for a minimum period of 6
                    months. In case of refusal of visa, all cancellation terms as specified will
                    apply. We are not responsible for any charges; fees or penalties that
                    subsequently may be imposed by any government agency or for charges incurred due
                    to incorrect travel documents.
                  </p>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">Payments</h3>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;A specific deposit as per our booking form is required
                    within 2 days of confirmation of your tour arrangements to hold the reservation.
                    Certain tours may have different payment requirements and these will be advised
                    at the time of booking. Pre-payment for all services should be made to our
                    offices  in the respective currencies (INR/EURO/USD) or as applicable. Any
                    Credit /card payments will incur a minimum 5% surcharge and should be done at
                    least 2 weeks or more prior to travel date. The Credit Card should be swiped in
                    our office, no online Credit Card payments are accepted.
                  </p>
                </div>
              </>
            )}
            {tag === detailsTag.PRIVACY_TAG && (
              <>
                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">PRIVACY NOTICE</h3>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;This privacy notice for The Gujarati Traveller,
                    describes how and why we might collect, store, use, and/or share
                    (&quot;process&quot;) your information when you use our services
                    (&quot;Services&quot;), such as when you: visit our website.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;Questions or concerns? Reading this privacy notice will
                    help you understand your privacy rights and choices. If you do not agree with
                    our policies and practices, please do not use our Services. If you still have
                    any questions or concerns, please contact us at thegujaratitraveller@gmail.com
                  </p>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">SUMMARY OF KEY POINTS</h3>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;This summary provides key points from our privacy
                    notice, but you can find out more details about any of these topics by clicking
                    the link following each key point or by using our table of contents below to
                    find the section you are looking for. You can also click here to go directly to
                    our table of contents.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;What personal information do we process? When you visit,
                    use, or navigate our Services, we may process personal information depending on
                    how you interact with  Services, the choices you make, and the products and
                    features you use.
                  </p>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">1. WHAT INFORMATION DO WE COLLECT?</h3>
                  <p className="mt-1 text-justify text-[12px]">
                    - Personal information you disclose to us
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    - We collect personal information that you provide to us.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;We collect personal information that you voluntarily
                    provide to us when you express an interest in obtaining information about us or
                    our products and Services, when you participate in activities on the Services,
                    or otherwise when you contact us.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;Personal Information Provided by You. The personal
                    information that we collect depends on the context of your interactions with us
                    and the Services, the choices you make, and the products and features you use.
                    The personal information we collect may include the following:
                  </p>
                  <ul className="mt-1 text-justify text-[14px]">
                    <li>- names</li>
                    <li>- phone numbers</li>
                    <li>- email addresses</li>
                  </ul>
                  <p className="mt-1 text-justify text-[12px]">
                    All personal information that you provide to us must be true, complete, and
                    accurate, and you must notify us of any changes to such personal information.
                  </p>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;We process your information to provide, improve, and
                    administer our Services, communicate with you, for security and fraud
                    prevention, and to comply with law. We may also process your information for
                    other purposes with your consent.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;We process your personal information for a variety of
                    reasons, depending on how you interact with our Services.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;To save or protect an individual&#39;s vital interest.
                    We may process your information when necessary to save or protect an
                    individual's vital interest, such as to prevent harm.
                  </p>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">
                    3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                  </h3>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;We only process your personal information when we
                    believe it is necessary and we have a valid legal reason (ie, legal basis) to do
                    so under applicable law, like anth your consent, to comply with laws, to provide
                    you with services to enter into or fulfil our contractual obligations to protect
                    your rights, or to fulfil our legitimate business interests
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;The General Data Protection Regulation (GDPR) and UK
                    GDPR require us to explain the valid legal bases we rely on in order to process
                    your personal information. As such, we may rely on the following legal bases to
                    process your personal information:
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;Legal Obligations. We may process your information where
                    we believe it is necessary for compliance with our legal obligations, such as to
                    cooperate with a law enforcement body or regulatory agency, exercise or defend
                    our legal rights, or disclose your information as evidence in legation in which
                    we are involved.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;Vital interests. We may process your information where
                    we believe it is necessary to protect your vital interests or the vital
                    interests of a third party, such as situations involving potential threats to
                    the safety of any person.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;If we have reasonable grounds to believe an individual
                    has been, is, or may be victim of financial abuse.
                  </p>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">
                    4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </h3>
                  <p className="mt-1 text-justify text-[12px]">
                    We may share information in specific situations described in this section and/or
                    with the following third parties
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    We may need to share your personal information in the following situations:
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    • Business Transfers. We may share or transfer your information in connection
                    with, or during negotiations of, any merger, sale of company assets, financing
                    or acquisition of all or a portion of our business to another company.
                  </p>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">5. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;We keep your information for as long as necessary to
                    fulfil the purposes outlined in this privacy notice unless otherwise required by
                    law.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;We will only keep your personal information for as long
                    as it is necessary for the purposes set out in this privacy notice, unless a
                    longer retention period is required or permitted
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;When we have no ongoing legitimate business need to
                    process your personal information, we will either delete or anonymize such
                    information, or, if this is not possible (for example, because your personal
                    information has been stored in backup archives), then we will securely store
                    your personal information and isolate it from any further processing until
                    deletion is possible.
                  </p>
                </div>

                <div
                  className="bg-color-black p-3 text-white rounded-[6px] mt-3"
                  data-aos="flip-up"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <h3 className="text-[16px]">6. DO WE COLLECT INFORMATION FROM MINORS?</h3>
                  <p className="mt-1 text-justify text-[12px]">
                    We do not knowingly collect data from or market to children under 18 years of
                    age.
                  </p>
                  <p className="mt-1 text-justify text-[12px]">
                    &nbsp;&nbsp;&nbsp;&nbsp;We do not knowingly solicit data from or market to
                    children under 18 years of age. By using the Services, you represent that you
                    are at least 18 or that you are the parent or guardian of such a minor and
                    consent to such minor dependent&#39;s use of the Services. If we learn that
                    personal information from users less than 18 years of age has been collected, we
                    will deactivate the account and take reasonable measures to promptly delete such
                    data from our records. If you become aware of any data we may have collected
                    from children under age 18, please contact us at{' '}
                    <u>thegujaratitraveller@gmail.com</u>
                  </p>
                </div>
              </>
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default TripDetailsModal;
