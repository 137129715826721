import React from 'react';
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import { FiTrash2, FiEye, FiDownloadCloud } from 'react-icons/fi';
import { useInquiry } from './hook/useInquiry';
import Select from 'react-select';
import { DateRangePicker } from 'rsuite';
import { convertDateIntoDDMMYYYY, getRanges } from '../../../utils/helper';

const { afterToday } = DateRangePicker;

const Inquiry = () => {
  const {
    inquiryData,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    deleteInquiryHandler,

    packageOptions,
    filterData,
    changeDateHandler,
    changePackageHandler,

    getInquiryForContent,
    fetchInquiryData
  } = useInquiry();
  let index = page * rowsPerPage;

  return (
    <>
      <Box className="top-bar">
        <Grid
          container
          justifyContent={'space-between'}
          alignItems={'center'}
          className="top-bar-wrapper"
        >
          <Grid item xs={4} className="top-bar-left">
            <DateRangePicker
              name="date"
              value={filterData.date}
              onChange={changeDateHandler}
              ranges={getRanges()}
              disabledDate={afterToday()}
              format="dd-MM-yyyy"
            />
          </Grid>

          <Grid item xs={8} className="top-bar-right">
            <Grid container spacing={2} justifyContent={'flex-end'}>
              <Grid item>
                <Select
                  name="package"
                  className="filter-select-options"
                  placeholder="Select Package"
                  value={filterData.package}
                  onChange={changePackageHandler}
                  options={packageOptions}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(140, 199, 20, 0.4);',
                      primary: '#8CC714'
                    }
                  })}
                />
              </Grid>

              <Grid item>
                <Button className="btn btn-primary" onClick={() => fetchInquiryData(1)}>
                  <FiDownloadCloud /> Excel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className="card">
        <TableContainer className="table-wrapper">
          <Table className="inquiry-table">
            <TableHead>
              <TableRow>
                <TableCell align="left">No</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Phone</TableCell>
                <TableCell align="left">Location</TableCell>
                <TableCell align="left">Inquiry For</TableCell>
                <TableCell align="left">Package</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inquiryData.data.length ? (
                inquiryData.data.map((row) => {
                  return (
                    <>
                      <TableRow key={'inquiry_' + row._id}>
                        <TableCell align="left">{(index += 1)}.</TableCell>
                        <TableCell align="left">{convertDateIntoDDMMYYYY(row.createdAt)}</TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.phone}</TableCell>
                        <TableCell align="left">{row.city}</TableCell>
                        <TableCell align="left">{getInquiryForContent(row.inquiryFor)}</TableCell>
                        <TableCell align="right">{row.package}</TableCell>
                        <TableCell align="center">
                          <Box className="table-action-btn">
                            <Button className="btn btn-primary">
                              <FiEye size={15} />
                            </Button>
                            <Button
                              className="btn btn-primary"
                              onClick={deleteInquiryHandler.bind(null, row._id)}
                            >
                              <FiTrash2 size={15} />
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" colSpan={9}>
                          - {row.message}
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }} colSpan={8}>
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={inquiryData.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default Inquiry;
