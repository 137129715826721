import React from 'react';
import { memories_data } from '../../../utils/data';
// import Slider from "react-slick";
// import { handleNextSlide, handlePrevSlide } from "../../../utils/helper";
// import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import Container from '../../../components/Container';

const OurMemoriesSection = ({ openScreenCastModalHandler }) => {
  // const gallerySliderRef = useRef(null);

  // const gallery_settings = {
  //   fade: true,
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   lazyLoad: true,
  //   ref: gallerySliderRef,

  //   responsive: [
  //     {
  //       breakpoint: 1536,
  //       settings: {
  //         slidesToShow: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 1280,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 640,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 576,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 412,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };
  return (
    <>
      {/* memories section start */}
      <Container>
        <div
          id="memories"
          className="py-[32px] sm:py-[48px] md:py-[52px] lg:py-[60px] xl:py-[60px] px-0"
        >
          <h1 className="text-primary">Our Memories</h1>
          <span className="border-green" />
          <div className="flex flex-row gap-[16px] justify-between items-center">
            <p className="text-secondary">Our photo gallery on trip.</p>
            {/* <div className="slider-action">
              <div
                className="btn-prev"
                onClick={handlePrevSlide.bind(null, gallerySliderRef)}
              >
                <IoChevronBack />
              </div>
              <div
                className="btn-next"
                onClick={handleNextSlide.bind(null, gallerySliderRef)}
              >
                <IoChevronForward />
              </div>
            </div> */}
          </div>
          {/* <div className="w-full mb-0 mt-[32px] mx-auto overflow-x-hidden">
            <Slider {...gallery_settings}>
              {memories_data.map((item, index) => (
                <div
                  key={item.id + "_memory"}
                  className="relative"
                  onClick={() => {
                    openScreenCastModalHandler(item.image);
                  }}
                  data-aos="flip-left"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                  data-aos-once="false"
                >
                  <img
                    src={item.image}
                    alt={`Slide ${index + 1}`}
                    // width={280}
                    // height={370}
                    className="w-full sm:w-[90%] h-[480px] 2xl:h-[520px] rounded-lg cursor-pointer"
                    // className="w-[95%] h-auto rounded-lg cursor-pointer"
                    style={{
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}
                  />
                </div>
              ))}
            </Slider>
          </div> */}
          <div className="mx-auto mt-5">
            <div className="-m-1 flex flex-wrap md:-m-2">
              <div className="flex w-1/2 flex-wrap">
                <div
                  className="w-1/2 p-1 md:p-2"
                  onClick={() => {
                    openScreenCastModalHandler(memories_data[3].image);
                  }}
                >
                  <img
                    alt="gallery"
                    className="block h-full w-full rounded-lg object-cover object-center"
                    // src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(70).webp"
                    src={memories_data[3].image}
                  />
                </div>

                <div
                  className="w-1/2 p-1 md:p-2"
                  onClick={() => {
                    openScreenCastModalHandler(memories_data[6].image);
                  }}
                >
                  <img
                    alt="gallery"
                    className="block h-full w-full rounded-lg object-cover object-center"
                    // src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp"
                    src={memories_data[6].image}
                  />
                </div>

                <div
                  className="w-full p-1 md:p-2"
                  onClick={() => {
                    openScreenCastModalHandler(memories_data[5].image);
                  }}
                >
                  <img
                    alt="gallery"
                    className="block h-full w-full rounded-lg object-cover object-center"
                    // src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
                    src={memories_data[5].image}
                  />
                </div>
              </div>

              <div className="flex w-1/2 flex-wrap">
                <div
                  className="w-full p-1 md:p-2"
                  onClick={() => {
                    openScreenCastModalHandler(memories_data[2].image);
                  }}
                >
                  <img
                    alt="gallery"
                    className="block h-full w-full rounded-lg object-cover object-center"
                    // src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(74).webp"
                    src={memories_data[2].image}
                  />
                </div>

                <div
                  className="w-1/2 p-1 md:p-2"
                  onClick={() => {
                    openScreenCastModalHandler(memories_data[4].image);
                  }}
                >
                  <img
                    alt="gallery"
                    className="block h-full w-full rounded-lg object-cover object-center"
                    // src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(75).webp"
                    src={memories_data[4].image}
                  />
                </div>

                <div
                  className="w-1/2 p-1 md:p-2"
                  onClick={() => {
                    openScreenCastModalHandler(memories_data[1].image);
                  }}
                >
                  <img
                    alt="gallery"
                    className="block h-full w-full rounded-lg object-cover object-center"
                    // src="https://tecdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(77).webp"
                    src={memories_data[1].image}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {/* memories section end */}
    </>
  );
};

export default OurMemoriesSection;
