import { Box, Fade, Modal } from '@mui/material';
import React from 'react';
import { FiPlus } from 'react-icons/fi';

const ScreenCastModal = ({ isScreenCastOpen, closeScreenCastModalHandler, image }) => {
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isScreenCastOpen}
        onClose={closeScreenCastModalHandler}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={isScreenCastOpen} onClick={closeScreenCastModalHandler}>
          <Box
            className="modal-screencast-slider 
            w-[100%] h-[100%]"
          >
            <Box className="modal-close" onClick={closeScreenCastModalHandler}>
              <FiPlus />
            </Box>
            <img
              src={image}
              alt="screencast"
              data-aos="zoom-in"
              data-aos-easing="linear"
              data-aos-duration="500"
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ScreenCastModal;
