import React, { useRef } from 'react';
import Container from '../../../components/Container';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import Slider from 'react-slick';
import { handleNextSlide, handlePrevSlide } from '../../../utils/helper';
import { upcoming_data } from '../../../utils/data';

const UpcomingTripSection = ({ openInquiryModalHandler }) => {
  const upcomingTripSliderRef = useRef(null);
  const upciming_trip_settings = {
    dots: false,
    autoplay: false,
    autoplaySpeed: 2500,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    lazyLoad: true,
    ref: upcomingTripSliderRef,

    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 412,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <>
      {/* upcoming trip section start */}
      <Container>
        <div
          id="upcoming_trips"
          className="py-[32px] sm:py-[48px] md:py-[52px] lg:py-[60px] xl:py-[60px] px-0"
        >
          <div className="flex flex-row gap-[16px] justify-between items-center">
            <h1 className="text-primary">Upcoming Trip</h1>
            <div className="slider-action">
              <div className="btn-prev" onClick={handlePrevSlide.bind(null, upcomingTripSliderRef)}>
                <IoChevronBack />
              </div>
              <div className="btn-next" onClick={handleNextSlide.bind(null, upcomingTripSliderRef)}>
                <IoChevronForward />
              </div>
            </div>
          </div>
          <div className="w-full mb-0 mt-[32px] mx-auto overflow-x-hidden">
            {/* <div className="w-full mb-0 mt-[32px] mx-auto overflow-x-hidden"> */}
            <Slider {...upciming_trip_settings}>
              {upcoming_data.map((item, index) => (
                <div
                  key={item.id + '_upcoming'}
                  // className="h-[700px] cursor-pointer"
                  className="cursor-pointer"
                  data-aos="zoom-in-up"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                  onClick={openInquiryModalHandler.bind(null, `Trip.${item.place}`)}
                >
                  <img
                    src={item.image}
                    alt={`Slide ${index + 1}`}
                    // className="w-full sm:w-[90%] h-full rounded-[10px]"
                    className="w-full sm:w-[90%] h-auto rounded-[6px]"
                    style={{
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                    }}
                  />
                </div>
              ))}
            </Slider>
            {/* </div> */}
          </div>
        </div>
      </Container>
      {/* upcoming trip section end */}
    </>
  );
};

export default UpcomingTripSection;
