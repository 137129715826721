import React from 'react';
import {
  FaPhone,
  FaInstagram,
  FaYoutube,
  FaWhatsapp,
  FaTelegramPlane,
  FaMapMarkerAlt,
  FaEnvelope
} from 'react-icons/fa';
import { detailsTag } from '../../../utils/constants';
import Container from '../../../components/Container';

const ContactUsSection = ({ openDetailModalHandler, openInquiryModalHandler }) => {
  const handleNavClick = (e, sectionId) => {
    e.preventDefault();
    // const section = document.querySelector(sectionId);
    // section.scrollIntoView({ behavior: "smooth" });
    if (sectionId === '#home') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      const section = document.querySelector(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <>
      {/* contact us section start */}
      <div
        id="contact"
        className="py-[32px] sm:py-[48px] md:py-[52px] lg:py-[68px] xl:py-[80px] px-0 bg-color-black"
      >
        <Container>
          <div className="flex items-start justify-between flex-wrap flex-col gap-[30px] sm:gap-[0px] sm:flex-row">
            {/* <div
              className="grid grid-rows-4 grid-cols-1 gap-[16px] sm:grid-rows-2 sm:grid-cols-2 sm:justify-between
                md:grid-rows-1 md:grid-cols-4 md:justify-between"
            > */}
            {/* <div className="grid grid-rows-2 grid-cols-2 justify-center gap-y-[20px] md:flex md:flex-row md:justify-between"> */}
            {/* first */}
            <div className="flex flex-col justify-start items-start gap-[12px]">
              <h5 className="text-white font-bold border-b-2 border-b-color-green">Menu</h5>
              <a
                href="#home"
                className="text-white text-[14px] font-normal"
                onClick={(event) => {
                  handleNavClick(event, '#home');
                }}
              >
                Home
              </a>
              <a
                href="#packages"
                className="text-white text-[14px] font-normal"
                onClick={(event) => {
                  handleNavClick(event, '#packages');
                }}
              >
                Travel
              </a>
              <a
                href="#vlog"
                className="text-white text-[14px] font-normal"
                onClick={(event) => {
                  handleNavClick(event, '#vlog');
                }}
              >
                Vlog
              </a>
              <a
                href="#reviews"
                className="text-white text-[14px] font-normal"
                onClick={(event) => {
                  handleNavClick(event, '#reviews');
                }}
              >
                Reviews
              </a>
            </div>

            {/* second */}
            <div className="flex flex-col justify-start items-start gap-[12px]">
              <h5 className="text-white font-bold border-b-2 border-b-color-green">Information</h5>
              {/* <p className="text-white text-[14px] font-normal">Destinations</p>
                <p className="text-white text-[14px] font-normal">Supports</p> */}
              <p
                className="text-white text-[14px] font-normal cursor-pointer"
                onClick={() => openDetailModalHandler(detailsTag.TERMS_CONDITIONS_TAG, {})}
              >
                Terms & <wbr /> Conditions
              </p>
              <p
                className="text-white text-[14px] font-normal cursor-pointer"
                onClick={() => openDetailModalHandler(detailsTag.PRIVACY_TAG, {})}
              >
                Privacy
              </p>
            </div>

            {/* third */}
            <div className="flex flex-col justify-start items-start gap-[12px]">
              <h5 className="text-white font-bold border-b-2 border-b-color-green">Contact Info</h5>
              <span className="text-white text-[14px] font-normal flex flex-row items-center gap-[8px]">
                <FaPhone />
                <p>+91 9664502121</p>
              </span>
              <span className="text-white text-[14px] font-normal flex flex-row items-center gap-[8px]">
                <FaEnvelope />
                <p className="w-full sm:w-[55%] break-words lg:w-auto">
                  thegujaratitraveller@gmail.com
                </p>
              </span>
              <span className="text-white text-[14px] font-normal flex flex-row items-center gap-[8px]">
                <FaMapMarkerAlt />
                <p>Gandhinagar, Gujarat</p>
              </span>
            </div>

            {/* fourth */}
            <div className="flex flex-col justify-start items-start gap-[12px]">
              <h5 className="text-white font-bold border-b-2 border-b-color-green">Follow us on</h5>
              <div className="flex flex-row gap-[20px]">
                <a
                  // href="tel:+919664502121"
                  href="https://wa.me/919664502121"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white text-[14px] font-normal hover:text-color-green"
                >
                  <FaWhatsapp className="text-[32px]" />
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.youtube.com/@thegujaratitraveller"
                  className="text-white text-[14px] font-normal hover:text-color-green"
                >
                  <FaYoutube className="text-[32px]" />
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=" https://instagram.com/thegujaratitraveller?igshid=YmMyMTA2M2Y="
                  className="text-white text-[14px] font-normal hover:text-color-green"
                >
                  <FaInstagram className="text-[32px]" />
                </a>
              </div>
              <div
                className="bg-color-green text-white px-[15px] py-[10px] rounded-md cursor-pointer"
                onClick={openInquiryModalHandler.bind(null, '')}
              >
                <p className="flex flex-row items-center gap-[5px] text-[14px] md:text-[14px] lg:text-[16px]">
                  Get In Touch <FaTelegramPlane />
                </p>
              </div>
            </div>
            {/* </div> */}
          </div>
        </Container>
      </div>
      {/* contact us section end */}
    </>
  );
};

export default ContactUsSection;
