export const detailsTag = {
  TRIP_TAG: 'trip',
  TERMS_CONDITIONS_TAG: 'terms',
  PRIVACY_TAG: 'privacy'
};

// API Constants------------------------

export const StorageName = 'theGujaratiTraveller';

// Auth API
export const LOGIN_API = '/auth/login';

// Inquiry API

export const GET_ALL_INQUIRY_API = '/inquiry';
export const STORE_INQUIRY_API = '/inquiry';
export const DELETE_INQUIRY_API = '/inquiry';
