import React from 'react';
import { FaYoutube } from 'react-icons/fa';
import Container from '../../../components/Container';
import { vlog_data } from '../../../utils/data';

const OurVlogSection = () => {
  return (
    <>
      {/* vblog section section start */}
      <Container>
        <div
          id="vlog"
          className="py-[32px] sm:py-[48px] md:py-[52px] lg:py-[60px] xl:py-[60px] px-0"
        >
          <h1 className="text-primary">Our Vlog</h1>
          <span className="border-green" />
          <div className="flex flex-row justify-between items-center">
            <p className="text-secondary">An insight the incredible experience in the world</p>
          </div>
          <div className="flex flex-col sm:flex-row justify-center items-center gap-[20px] mt-[32px] w-full">
            <div
              className="w-[80%] sm:w-[50%]"
              data-aos="zoom-in"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <img src={vlog_data.image} alt="who we are" className="w-full h-full rounded-[6px]" />
            </div>
            <div className="w-[90%] sm:w-[50%]">
              <h2 className="mb-[20px] leading-none text-[22px] sm:text-[26px] md:text-[32px] lg:text-[48px] font-medium text-left">
                {vlog_data.title}
              </h2>
              <p
                className="xs:leading-[16px] mb-[20px] sm:leading-[20px] md:leading-[26px] lg:leading-[32px] xl:leading-[38px] 
                text-[16px] sm:text-[14px] md:text-[14px] lg:text-[18px] font-normal text-justify"
              >
                &nbsp;&nbsp;&nbsp;&nbsp;{vlog_data.description}
              </p>
              <div
                className="bg-[#FFFFFF] border-2 border-[#FF0000] w-max rounded-[6px] md:rounded-[8px]
                py-[8px] px-[12px] md:py-[10px] md:px-[14px]
                flex flex-row justify-center items-center tracking-wide"
              >
                <a
                  href={vlog_data.video_link}
                  rel="noreferrer"
                  target="_blank"
                  className="text-black font-bold text-[12px] md:text-[16px] flex flex-row justify-center items-center gap-[6px]"
                >
                  <FaYoutube size={30} fill="#FF0000" />{' '}
                  <span style={{ color: '#000' }}> WATCH </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {/* vblog section end */}
    </>
  );
};

export default OurVlogSection;
