import { useCallback, useEffect, useState } from 'react';
import { getAllInquiry, removeInquiry } from '../../../../service/inquiry';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInquiry, storeInquiries } from '../../../../store/inquiry';
import { setLoading } from '../../../../store/loader';
import {
  ConfirmationAlert,
  MESSAGE_ICON,
  MESSAGE_TITLE,
  ShowMessage,
  convertDateIntoDDMMYYYY,
  convertDateIntoYYYYMMDD
} from '../../../../utils/helper';
import axios from 'axios';
import { GET_ALL_INQUIRY_API } from '../../../../utils/constants';
import { getAuthToken } from '../../../../utils/auth';

export const useInquiry = () => {
  const dispatch = useDispatch();
  const inquiryData = useSelector((state) => state.inquiry);

  const [filterData, setFilterData] = useState({
    package: { value: '', label: 'All' },
    date: [new Date(), new Date()]
  });

  const changeDateHandler = (value) => {
    setFilterData((prev) => ({ ...prev, date: value }));
  };
  const changePackageHandler = (value) => {
    setFilterData((prev) => ({ ...prev, package: value }));
    setPage(0);
  };

  // pagination start
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };
  // pagination end

  const packageOptions = [
    { value: '', label: 'All' },
    { value: 'silver', label: 'Silver' },
    { value: 'gold', label: 'Gold' },
    { value: 'platinum', label: 'Platinum' }
  ];

  const fetchInquiryData = useCallback(
    async (excel = 0) => {
      try {
        dispatch(setLoading(true));
        const params = {
          page: page + 1,
          limit: rowsPerPage,
          startDate: convertDateIntoYYYYMMDD(filterData.date[0]),
          endDate: convertDateIntoYYYYMMDD(filterData.date[1]),
          package: filterData.package.value
        };
        if (excel) {
          params.excel = excel;
        }
        // if not click on excel, than simply fetch all records related to filter
        if (excel !== 1) {
          const response = await getAllInquiry(params);
          if (response.status === 200) {
            const payload = { count: response.data.totalDocs, data: response.data.docs };
            dispatch(storeInquiries(payload));
          } else {
            const payload = { count: 0, data: [] };
            dispatch(storeInquiries(payload));
          }
        }
        // if click on excel export than call API for response type blob
        else {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${GET_ALL_INQUIRY_API}`,
            {
              params,
              responseType: 'blob',
              headers: { Authorization: getAuthToken() }
            }
          );
          if (response.status === 200) {
            const data = response.data;
            const blob = new Blob([data]);
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `Inquiry_${convertDateIntoDDMMYYYY(
              filterData.date[0]
            )}_${convertDateIntoDDMMYYYY(filterData.date[1])}.xlsx`;
            anchor.click();
          } else {
            ShowMessage(MESSAGE_ICON.error, MESSAGE_TITLE.error, 'No Records Found!');
          }
        }
      } catch (error) {
        console.log(error.response);
        if (error?.response?.status === 404) {
          ShowMessage(MESSAGE_ICON.error, MESSAGE_TITLE.error, 'No Records Found!');
        } else {
          ShowMessage(
            MESSAGE_ICON.error,
            MESSAGE_TITLE.error,
            error.message || 'Something went wrong!'
          );
        }
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, page, rowsPerPage, filterData]
  );

  useEffect(() => {
    fetchInquiryData();
  }, [fetchInquiryData]);

  // delete btn click handler
  const deleteInquiryHandler = async (id) => {
    try {
      const result = await ConfirmationAlert();
      console.log(result);
      if (result.isConfirmed) {
        dispatch(setLoading(true));
        const response = await removeInquiry(id);
        if (response.status === 200) {
          ShowMessage(MESSAGE_ICON.success, MESSAGE_TITLE.success, response.message);
          dispatch(deleteInquiry({ id: response.data._id }));
        } else {
          ShowMessage(MESSAGE_ICON.error, MESSAGE_TITLE.error, response.message);
        }
      }
    } catch (error) {
      console.log(error);
      ShowMessage(MESSAGE_ICON.error, MESSAGE_TITLE.error, error.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getInquiryForContent = (text) => {
    if (text) {
      const [title, place] = text.split('.');
      return (
        <>
          <b>{title}</b>
          <br />
          <i>-{place}</i>
        </>
      );
    } else {
      return '-';
    }
  };

  return {
    inquiryData,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    deleteInquiryHandler,

    packageOptions,
    filterData,
    changeDateHandler,
    changePackageHandler,

    getInquiryForContent,
    fetchInquiryData
  };
};
