import { Accordion, AccordionSummary, Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { GoPeople } from 'react-icons/go';
import { RxDashboard } from 'react-icons/rx';
import { logoutHandler } from '../utils/auth';

const MenuList = [
  {
    id: 1,
    path: '/admin',
    label: 'Dashboard',
    icon: <RxDashboard />
  },
  {
    id: 2,
    path: 'inquiry',
    label: 'Inquiry',
    icon: <GoPeople />
  }
];

const Sidebar = () => {
  const navigate = useNavigate();

  // sidebar menu accordion
  const [activeMenu, setActiveMenu] = useState(MenuList[0].label);
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const logoutBtnHandler = () => {
    logoutHandler();
  };

  return (
    <>
      {/* sidebar menu */}
      <Box className="sidebar-menu">
        <div>
          {MenuList?.map((row) => {
            return (
              <Accordion
                key={row.id + '_menulist'}
                expanded={expanded === row.lable}
                onChange={handleChange(row.label)}
                className="menu-list"
                onClick={() => {
                  setActiveMenu(row.label);
                  navigate(row.path);
                }}
              >
                <AccordionSummary
                  className={`menu-title ${activeMenu === row.label ? 'active-menu' : ''}`}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>
                    {row.icon}
                    {row.label}
                  </Typography>
                </AccordionSummary>
              </Accordion>
            );
          })}
          {/* <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            className="menu-list"
            onClick={() => navigate('dashboard')}
          >
            <AccordionSummary className="menu-title" aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography>
                <RxDashboard />
                Dashboard
              </Typography>
            </AccordionSummary>
          </Accordion> */}

          {/* <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            className="menu-list"
            onClick={() => navigate('inquiry')}
          >
            <AccordionSummary className="menu-title" aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography>
                <GoPeople />
                Inquiy
              </Typography>
            </AccordionSummary>
          </Accordion> */}

          {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="menu-list">
            <AccordionSummary className="menu-title" expandIcon={<FiChevronRight />} aria-controls="panel2bh-content" id="panel2bh-header">
              <Typography>
                <FiGrid /> Master
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="sub-menu-list">
              <Box className={`sub-menu-link ${activeTab === 'sub' && 'active'}`}>
                <Typography>
                  <FiSquare />
                  Sub menu 1
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion> */}
        </div>
        <div>
          <Accordion
            className="menu-list"
            onClick={() => {
              logoutBtnHandler();
            }}
          >
            <AccordionSummary
              className={`menu-title`}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography>
                <FiLogOut /> Logout
              </Typography>
            </AccordionSummary>
          </Accordion>
        </div>
      </Box>
    </>
  );
};

export default Sidebar;
