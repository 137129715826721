import React, { useState } from 'react';
import ScreenCastModal from '../../components/ScreenCastModal';
import InquiryModal from '../../components/InquiryModal';
import TripDetailsModal from '../../components/TripDetailsModal';
import HeroSection from './Sections/HeroSection';
import WhoWeAreSection from './Sections/WhoWeAreSection';
import PopularDestinationSection from './Sections/PopularDestinationSection';
import InternationalDestinationSection from './Sections/InternationalDestinationSection';
import UniqueItinerarySection from './Sections/UniqueItinerarySection';
import OurVlogSection from './Sections/OurVlogSection';
import UpcomingTripSection from './Sections/UpcomingTripSection';
import OurMemoriesSection from './Sections/OurMemoriesSection';
import ExperiencesSection from './Sections/ExperiencesSection';
import ContactUsSection from './Sections/ContactUsSection';

const UserPage = () => {
  const [tag, setTag] = useState('');

  //  view photos modal
  const [isScreenCastOpen, setIsScreenCastOpen] = useState(false);
  const [screenCastImage, setScreenCastImage] = useState(null);
  const openScreenCastModalHandler = (img) => {
    setIsScreenCastOpen(true);
    setScreenCastImage(img);
  };
  const closeScreenCastModalHandler = () => {
    setIsScreenCastOpen(false);
  };

  //  trip detail modal
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [tripDetail, setTripDetail] = useState({});
  const [place, setPlace] = useState('');
  const openDetailModalHandler = (tag, details, place) => {
    setPlace(place);
    setTripDetail(details);
    setTag(tag);
    setIsDetailModalOpen(true);
  };
  const closeDetailModalHandler = () => {
    setIsDetailModalOpen(false);
  };

  // inquiry modal
  const [isInquiryModalOpen, setIsInquiryModalOpen] = useState(false);
  const [inquiryFor, setInquiryFor] = useState('');
  const openInquiryModalHandler = (inquiryForPackage) => {
    setIsInquiryModalOpen(true);
    setInquiryFor(inquiryForPackage);
  };
  const closeInquiryModalHandler = () => {
    setIsInquiryModalOpen(false);
  };

  return (
    <>
      <div className="w-full m-0 p-0">
        <HeroSection />
        <WhoWeAreSection />
        <PopularDestinationSection openDetailModalHandler={openDetailModalHandler} />
        <InternationalDestinationSection openInquiryModalHandler={openInquiryModalHandler} />
        <UniqueItinerarySection openInquiryModalHandler={openInquiryModalHandler} />
        <UpcomingTripSection openInquiryModalHandler={openInquiryModalHandler} />
        <OurVlogSection />
        <OurMemoriesSection openScreenCastModalHandler={openScreenCastModalHandler} />
        <ExperiencesSection />

        <ContactUsSection
          openDetailModalHandler={openDetailModalHandler}
          openInquiryModalHandler={openInquiryModalHandler}
        />
      </div>

      {isScreenCastOpen && (
        <ScreenCastModal
          isScreenCastOpen={isScreenCastOpen}
          closeScreenCastModalHandler={closeScreenCastModalHandler}
          image={screenCastImage}
        />
      )}

      {isInquiryModalOpen && (
        <InquiryModal
          isInquiryModalOpen={isInquiryModalOpen}
          closeInquiryModalHandler={closeInquiryModalHandler}
          inquiryFor={inquiryFor}
        />
      )}

      {isDetailModalOpen && (
        <TripDetailsModal
          isDetailModalOpen={isDetailModalOpen}
          detail={tripDetail}
          tag={tag}
          place={place}
          closeDetailModalHandler={closeDetailModalHandler}
          openInquiryModalHandler={openInquiryModalHandler}
        />
      )}
    </>
  );
};

export default UserPage;
