import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { LOGIN_API } from '../../../utils/constants';
import { MESSAGE_ICON, MESSAGE_TITLE, ShowMessage } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from '../../../utils/auth';
import { useDispatch } from 'react-redux';
import { setLogedInUser } from '../../../store/auth';

const BASE_PATH = process.env.REACT_APP_API_URL;

const validationSchema = Yup.object({
  username: Yup.string().required('Username is required.'),
  password: Yup.string().required('Password is required.')
});

export const useLogin = () => {
  const usernameRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const body = { username: values.username.trim(), password: values.password.trim() };
        const response = await axios.post(`${BASE_PATH}${LOGIN_API}`, body, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.data.status === 200) {
          const responseData = response.data.data;
          setAuthToken(responseData.token);
          dispatch(setLogedInUser(responseData.user));
          ShowMessage(MESSAGE_ICON.success, MESSAGE_TITLE.success, response.data.message);
          navigate('/admin', { replace: true });
        } else {
          ShowMessage(
            MESSAGE_TITLE.error,
            response.data.message || 'Something went wrong',
            MESSAGE_ICON.error
          );
        }
      } catch (error) {
        const errorData = error.response.data;
        ShowMessage(MESSAGE_ICON.error, MESSAGE_TITLE.error, errorData.message);
      }
    }
  });

  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  return {
    usernameRef,
    formik
  };
};
