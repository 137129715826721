import React, { useRef } from 'react';

import Google from '../../../assets/images/experience/Google_review_3.png';
import Container from '../../../components/Container';
import { handleNextSlide, handlePrevSlide } from '../../../utils/helper';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import Slider from 'react-slick';
import { experience_data } from '../../../utils/data';

const ExperiencesSection = () => {
  const reviewSliderRef = useRef(null);

  const review_settings = {
    autoplay: false,
    autoplaySpeed: 3000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    ref: reviewSliderRef,

    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 412,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <>
      {/* Traveler’s Experiences section start */}
      <Container>
        <div
          id="reviews"
          className="py-[32px] sm:py-[48px] md:py-[52px] lg:py-[60px] xl:py-[60px] px-0 review-section"
        >
          <h1 className="text-primary">Traveler’s Experiences</h1>
          <span className="border-green" />
          <div className="flex flex-row mt-[10px] justify-between items-center">
            {/* <p className="text-secondary">
              Here some awesome feedback from our travelers.
            </p> */}
            <a
              href="https://www.google.com/search?q=the+gujarati+traveller+reviews&sca_esv=559732191&ei=Yn_nZPnmBLmv2roPrvyi2AQ&oq=the+gujarati+traveller+review&gs_lp=Egxnd3Mtd2l6LXNlcnAiHXRoZSBndWphcmF0aSB0cmF2ZWxsZXIgcmV2aWV3KgIIADIFECEYoAEyBRAhGKABMgUQIRigAUirFlCqBli6DXABeACQAQCYAcoBoAGNCqoBBTAuNy4xuAEDyAEA-AEBwgIOEC4YgAQYxwEYrwEYsAPCAggQABiABBiwA8ICBRAAGIAEwgIGEAAYFhgewgIKECEYFhgeGA8YHcICBxAhGKABGAriAwQYASBBiAYBkAYD&sclient=gws-wiz-serp"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={Google}
                alt="google reviews"
                className="w-[100px] h-[50px] sm:w-[110px] sm:h-[60px] md:w-[120px] md:h-[60px] lg:w-[140px] lg:h-[80px]"
              />
            </a>
            <div className="slider-action">
              <div className="btn-prev" onClick={handlePrevSlide.bind(null, reviewSliderRef)}>
                <IoChevronBack />
              </div>
              <div className="btn-next" onClick={handleNextSlide.bind(null, reviewSliderRef)}>
                <IoChevronForward />
              </div>
            </div>
          </div>
          <div className="w-full mb-0 mt-[32px] mx-auto overflow-x-hidden">
            <Slider {...review_settings}>
              {experience_data.map((row, index) => (
                <div
                  data-aos="fade-left"
                  data-aos-anchor-placement="center-bottom"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                  key={index + '_review'}
                  className="bg-[#F5F6F7] w-[90%] sm:w-[80%] h-[320px] md:h-[360px] px-[18px] py-[10px] rounded-[10px] relative"
                >
                  <div className="flex flex-row justify-between">
                    <div
                      className="bg-color-black rounded-full text-white flex justify-center items-center
                      text-[22px] md:text-[32px] w-[70px] h-[70px] sm:w-[80px] sm:h-[80px] md:w-[90px] md:h-[90px]"
                    >
                      {row.prefix}
                    </div>
                  </div>

                  <div className="mt-[12px] h-[70%] flex flex-col justify-between">
                    <p className="text-[14px] md:text-[14px] lg:text-[16px] text-color-grey text-justify">
                      {row.description}
                    </p>
                    <h2 className="text-color-grey text-[16px] md:text-[18px] lg:text-[20px] font-semibold mb-[20px] self-start">
                      - {row.name}
                    </h2>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </Container>
      {/* Traveler’s Experiences section end */}
    </>
  );
};

export default ExperiencesSection;
