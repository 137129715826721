import { StorageName } from './constants';

export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem(StorageName, token);
  }
};

export const getAuthToken = () => {
  const token = localStorage.getItem(StorageName);
  return token;
};

export const clearStorage = () => {
  localStorage.clear();
};

export const logoutHandler = () => {
  clearStorage();
  window.location.href = '/login';
};
