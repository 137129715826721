import React from 'react';
import Who from '../../../assets/images/who_we_are/profile.jpg';
import Container from '../../../components/Container';

const WhoWeAreSection = () => {
  return (
    <>
      {/* who we are section section start */}
      <Container>
        <div
          id="who_we_are"
          className="py-[32px] sm:py-[48px] md:py-[52px] lg:py-[60px] xl:py-[60px] px-0"
        >
          <h1 className="text-primary">Who we are</h1>
          <span className="border-green" />
          <div className="flex flex-col sm:flex-row justify-center items-center gap-[20px] mt-[32px] w-full">
            <div
              className="w-[80%] sm:w-[50%]"
              data-aos="fade-right"
              data-aos-easing="linear"
              data-aos-duration="500"
            >
              <img src={Who} alt="who we are" className="w-full sm:w-[100%] h-auto rounded-[6px]" />
            </div>
            <div className="w-[90%] sm:w-[50%]">
              <p
                className="xs:leading-[18px] sm:leading-[22px] md:leading-[26px] lg:leading-[32px] xl:leading-[42px] 
                text-[16px] sm:text-[14px] md:text-[14px] lg:text-[18px] font-normal text-justify"
              >
                &nbsp;&nbsp;&nbsp;&nbsp;We are overjoyed to welcome you all to The Gujarati
                Traveller (TGT) Travel Company. TGT is the joint brainchild of{' '}
                <strong>Vidhi Patel</strong> and <strong>Jay Patel.</strong> We have been travelling
                for quite a while now and constantly feel why not to help like-minded people to plan
                a unique and joyful holiday like us! <br />
                &nbsp;&nbsp;&nbsp;&nbsp; With this idea in mind we thought of{' '}
                <strong>TGT Travel Company.</strong> We will help you to plan a personalised travel
                itinerary as per your requirements. So just dream of a destination and leave the
                rest to us! <br />
                &nbsp;&nbsp;&nbsp;&nbsp; We start here and it’s a long way, so happy journey!
                Cheers to life!
              </p>
            </div>
          </div>
        </div>
      </Container>
      {/* who we are section end */}
    </>
  );
};

export default WhoWeAreSection;
