import Fade from '@mui/material/Fade';
import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { storeInquiry } from '../service/inquiry';
import { useState } from 'react';
import { LuLoader2 } from 'react-icons/lu';

const validationSchema = Yup.object({
  fullName: Yup.string().required('Please enter your name.'),
  city: Yup.string()
    .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])/g, 'Please enter city and pincode both.')
    .required('Please enter your city and pincode.'),
  email: Yup.string().matches(
    /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
    'Please enter a valid email.'
  ),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits.')
    .required('Please enter your phone number'),
  message: Yup.string().required('Please enter your your message')
});

const InquiryModal = ({ isInquiryModalOpen, closeInquiryModalHandler, inquiryFor }) => {
  let timer;
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { values, handleBlur, handleChange, handleSubmit, handleReset, errors, touched } =
    useFormik({
      initialValues: {
        fullName: '',
        city: '',
        email: '',
        phone: '',
        package: 'silver',
        message: ''
      },
      validationSchema,
      onSubmit: async (values) => {
        try {
          setIsLoading(true);
          const payload = { ...values, name: values.fullName, inquiryFor: inquiryFor || 'Direct' };
          const response = await storeInquiry(payload);
          if (response.status === 201) {
            setMessage(true);
            handleReset();
          } else {
            setMessage(false);
          }
          timer = setTimeout(() => {
            setMessage('');
          }, [5000]);
        } catch (error) {
          console.log(error);
          setMessage(false);
        } finally {
          setIsLoading(false);
        }
      }
    });

  useEffect(() => {
    return () => clearTimeout(timer);
  }, [timer]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isInquiryModalOpen}
        onClose={closeInquiryModalHandler}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
        // data-aos="zoom-in"
        // data-aos-easing="ease-in"
        // data-aos-duration="500"
      >
        <Fade in={isInquiryModalOpen}>
          {/* <Box
            className="m-auto align-center mt-[25px] fixed top-1/2 left-1/2 
          rounded-[10px] w-[50%] h-[60%] bg-white"
          ></Box> */}
          <div>
            <div
              className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
              h-[80%] md:h-[90%] overflow-scroll bg-white p-6 rounded-[10px] w-[95%] sm:w-[80%] md:w-[75%] lg:w-[65%] xl:w-[50%]"
            >
              <div className="">
                <h2 className="text-color-black text-[28px] sm:text-[28px] md:text-[30px] lg:text-[32px]">
                  Get In Touch With Us...
                </h2>
                <hr />

                <form
                  className=""
                  onSubmit={(e) => {
                    e.preventDefault();
                    !isLoading && handleSubmit();
                  }}
                >
                  <div className="grid sm:grid-cols-2 mt-[22px] gap-6">
                    <div className="flex flex-col gap-[3px] w-full">
                      <label className="font-normal text-[16px] md:text-[14px]">Full name*</label>
                      <input
                        type="text"
                        name="fullName"
                        value={values.fullName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Eg, John Doe"
                        className={`bg-[#F8F8FF] p-3 rounded-[6px] border focus:border-color-green
                        ${
                          touched.fullName && errors.fullName
                            ? 'border-red-500'
                            : 'border-color-black'
                        }`}
                      />
                      <span className="error">
                        {errors.fullName && touched.fullName ? errors.fullName : ''}
                      </span>
                    </div>
                    <div className="flex flex-col gap-[3px] w-full">
                      <label className="font-normal text-[16px] md:text-[14px]">City*</label>
                      <input
                        type="text"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Eg, Ahemdabad, 35008"
                        className={`bg-[#F8F8FF] p-3 rounded-[6px] border border-color-black focus:border-color-green
                        ${touched.city && errors.city ? 'border-red-500' : 'border-color-black'}
                        `}
                      />
                      <span className="error">
                        {errors.city && touched.city ? errors.city : ''}
                      </span>
                    </div>
                  </div>

                  <div className="grid sm:grid-cols-2 mt-[18px] gap-6">
                    <div className="flex flex-col gap-[3px] w-full">
                      <label className="font-normal text-[16px] md:text-[14px]">
                        Email (optional)
                      </label>
                      <input
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="johndoe@gmail.com"
                        className={`bg-[#F8F8FF] p-3 rounded-[6px] border border-color-black focus:border-color-green`}
                      />
                      <span className="error">
                        {errors.email && touched.email ? errors.email : ''}
                      </span>
                    </div>
                    <div className="flex flex-col gap-[3px] w-full">
                      <label className="font-normal text-[16px] md:text-[14px]">
                        Phone number *
                      </label>
                      <input
                        type="number"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="+91"
                        className={`bg-[#F8F8FF] p-3 rounded-[6px] border border-color-black focus:border-color-green
                        ${touched.phone && errors.phone ? 'border-red-500' : 'border-color-black'}`}
                      />
                      <span className="error">
                        {errors.phone && touched.phone ? errors.phone : ''}
                      </span>
                    </div>
                  </div>

                  <div className="grid mt-[18px]">
                    <div className="flex flex-col gap-[3px] w-full">
                      <label className="font-normal text-[16px] md:text-[14px]">
                        Package inquiry for *
                      </label>
                      <div className="flex flex-col gap-[10px] sm:flex-row sm:gap-[24px]">
                        <div className="flex flex-row gap-[10px]">
                          <input
                            type="radio"
                            name="package"
                            id="silver"
                            checked={values.package === 'silver' ? true : false}
                            value="silver"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="bg-[#F8F8FF] p-3 rounded-[6px] border border-color-black focus:border-color-green"
                          />
                          <label htmlFor="silver">Silver</label>
                        </div>
                        <div className="flex flex-row gap-[10px]">
                          <input
                            type="radio"
                            name="package"
                            id="gold"
                            checked={values.package === 'gold' ? true : false}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={'gold'}
                            className="bg-[#F8F8FF] p-3 rounded-[6px] border border-color-black focus:border-color-green"
                          />
                          <label htmlFor="gold">Gold</label>
                        </div>
                        <div className="flex flex-row gap-[10px]">
                          <input
                            type="radio"
                            name="package"
                            id="platinum"
                            checked={values.package === 'platinum' ? true : false}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={'platinum'}
                            className="bg-[#F8F8FF] p-3 rounded-[6px] border border-color-black focus:border-color-green"
                          />
                          <label htmlFor="platinum">Platinum</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid mt-[18px]">
                    <div className="flex flex-col gap-[3px] w-full">
                      <label className="font-normal text-[16px] md:text-[14px]">Message *</label>
                      <textarea
                        type="text"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Write your message here..."
                        className={`bg-[#F8F8FF] p-3 rounded-[6px] border border-color-black focus:border-color-green
                        ${
                          touched.message && errors.message
                            ? 'border-red-500'
                            : 'border-color-black'
                        }`}
                      />
                      {errors.message && touched.message ? (
                        <span className="error">{errors.message}</span>
                      ) : null}
                    </div>
                  </div>

                  <div className="mt-[28px]">
                    <div className="flex flex-row items-center gap-[10px]">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="bg-color-green text-white px-[22px] py-[12px] md:px-[15px] md:py-[10px] rounded-md cursor-pointer hover:shadow-lg focus:shadow-lg"
                      >
                        {isLoading ? 'Submiting...' : 'Submit'}
                      </button>
                      <button
                        type="button"
                        disabled={isLoading}
                        className="bg-color-grey text-white px-[22px] py-[12px] md:px-[15px] md:py-[10px] rounded-md cursor-pointer hover:shadow-lg focus:shadow-lg"
                        onClick={() => {
                          handleReset();
                          closeInquiryModalHandler();
                        }}
                      >
                        Cancel
                      </button>
                      &nbsp;
                      {isLoading && (
                        <LuLoader2 size={25} color="#172432" className="animate-spin" />
                      )}
                    </div>
                    {message === true && (
                      <p className="text-green-600">
                        Your inquiry register successfully, our team will contact soon.
                      </p>
                    )}{' '}
                    {message === false && (
                      <p className="text-red-600">Something went wrong, please try again later.</p>
                    )}
                  </div>

                  <div className="mt-[28px] text-center">
                    <p>
                      Customise your personal travel itinerary with us.
                      <br />
                      <strong>Call: +91 96645 02121</strong> or fill the inquiry form. <br /> We
                      will get back to you with more exciting options.
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default InquiryModal;
