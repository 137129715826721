import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
  data: []
};

const InquirySlice = createSlice({
  name: 'inquiry',
  initialState,
  reducers: {
    storeInquiries(state, action) {
      state.count = action.payload.count;
      state.data = action.payload.data;
    },
    deleteInquiry(state, action) {
      const data = state.data.filter((row) => row._id !== action.payload.id);
      state.data = data;
      state.count = state.count - 1;
    }
  }
});

export const { storeInquiries, deleteInquiry } = InquirySlice.actions;
export default InquirySlice.reducer;
