import React from 'react';
import { getAuthToken } from '../../utils/auth';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, ...rest }) => {
  const token = getAuthToken();
  return token ? children : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
