import { DELETE_INQUIRY_API, GET_ALL_INQUIRY_API, STORE_INQUIRY_API } from '../utils/constants';
import { deleteCall, getCall, postCall } from './webRequest';

export const storeInquiry = async (payload) => {
  const response = await postCall(STORE_INQUIRY_API, payload);
  return response;
};

export const getAllInquiry = async (params) => {
  const response = await getCall(GET_ALL_INQUIRY_API, params);
  return response;
};

export const removeInquiry = async (id) => {
  const response = await deleteCall(DELETE_INQUIRY_API, id);
  return response;
};
