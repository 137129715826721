import { configureStore } from '@reduxjs/toolkit';

import LoaderReducer from './loader';
import AuthReducer from './auth';
import InquiryReducer from './inquiry';

const store = configureStore({
  reducer: {
    loader: LoaderReducer,
    auth: AuthReducer,
    inquiry: InquiryReducer
  }
});

export default store;
