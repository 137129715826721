import Hero1 from '../assets/images/hero/Agra.jpg';
import Hero2 from '../assets/images/hero/Ladakh-min.jpg';
import Hero3 from '../assets/images/hero/Vietnam-min.jpg';
import Hero4 from '../assets/images/hero/dubai.jpg';
import Hero5 from '../assets/images/hero/ladakh.jpg';

import POPULAR1 from '../assets/images/Popular_Destinations/Kerala.jpg';
import POPULAR2 from '../assets/images/Popular_Destinations/Andaman-Nicobar.jpeg';
import POPULAR3 from '../assets/images/Popular_Destinations/Char-Dham.jpg';
import POPULAR4 from '../assets/images/Popular_Destinations/Goa.jpeg';
import POPULAR5 from '../assets/images/Popular_Destinations/Kashmir.jpg';
import POPULAR6 from '../assets/images/Popular_Destinations/Leh-Ladakh.jpg';
import POPULAR7 from '../assets/images/Popular_Destinations/North-East.jpeg';
import POPULAR8 from '../assets/images/Popular_Destinations/Rajasthan.jpeg';
import POPULAR9 from '../assets/images/Popular_Destinations/Varanasi.jpeg';
import POPULAR10 from '../assets/images/Popular_Destinations/Spiti.jpg';

import INTERNATIONAL1 from '../assets/images/International_Destinations/Bali.jpg';
import INTERNATIONAL2 from '../assets/images/International_Destinations/Dubai.jpg';
import INTERNATIONAL3 from '../assets/images/International_Destinations/Maldives.jpg';
import INTERNATIONAL4 from '../assets/images/International_Destinations/Shrilanka.jpeg';
import INTERNATIONAL5 from '../assets/images/International_Destinations/Singapore.jpg';
import INTERNATIONAL6 from '../assets/images/International_Destinations/Vietnam.jpeg';
import INTERNATIONAL7 from '../assets/images/International_Destinations/Malaysia.jpg';
import INTERNATIONAL8 from '../assets/images/International_Destinations/Bangkok.jpg';
import INTERNATIONAL9 from '../assets/images/International_Destinations/Paris.jpg';

import UNIQUE1 from '../assets/images/Unique_Itinerary/Bandipur.jpeg';
import UNIQUE2 from '../assets/images/Unique_Itinerary/Pondicherry.jpg';
import UNIQUE3 from '../assets/images/Unique_Itinerary/Nainital.jpg';

import UPCOMING1 from '../assets/images/upcoming_trips/Andaman.jpg';
import UPCOMING2 from '../assets/images/upcoming_trips/Kerala.jpg';
import UPCOMING3 from '../assets/images/upcoming_trips/Ooty-bandipur.jpg';
import UPCOMING4 from '../assets/images/upcoming_trips/Spiti.jpg';

import VlogImage from '../assets/images/vlog/image.jpg';

import MEMORIES1 from '../assets/images/memories/1.jpg';
import MEMORIES2 from '../assets/images/memories/2.jpg';
import MEMORIES3 from '../assets/images/memories/3.jpg';
import MEMORIES4 from '../assets/images/memories/4.jpg';
import MEMORIES5 from '../assets/images/memories/5.jpg';
import MEMORIES6 from '../assets/images/memories/6.jpg';

export const navItems = [
  { name: 'Home', path: '#home' },
  { name: 'Packages', path: '#packages' },
  { name: 'Upcoming Trips', path: '#upcoming_trips' },
  { name: 'Vlog', path: '#vlog' },
  { name: 'Memories', path: '#memories' },
  { name: 'Reviews', path: '#reviews' },
  { name: 'Contact Us', path: '#contact' }
];

export const hero_section_images = [Hero1, Hero2, Hero3, Hero4, Hero5];

export const popular_destinations_data = [
  {
    id: 1,
    place: 'Kerala',
    image: POPULAR1,
    price: '26,499',
    description:
      'Experience God’s Own Country - Kerala’s Backwater, Tea plantations, wildlife, Ayurveda, Beaches Culture with us.',
    duration: {
      day: 7,
      night: 6
    },
    details: {
      heading: 'Exotic Kerala 7D/6N',
      subHeading: '2N Munnar | 1N thekkady | 1N Alleppey | 2N Kovalam/Poovar',
      short_itinerary: [
        'Day - 1 Kochi to munnar',
        'Day - 2 Munnar sightseeing',
        'Day - 3 Munnar to Thekkady',
        'Day - 4 Thekkady to Alleppey',
        'Day - 5 Alleppey to Kovalam',
        'Day - 6 Kovalam to Poovar to Trivandram',
        'Day - 7 Trivandram sightseeing & Return'
      ],
      inclusions: [
        `Accommodation on twin sharing basis inclusive of Buffet Breakfast.`,
        `All transfers and sightseeing arrangements by AC Vehicle as per the Group Size`,
        `Vehicle at your disposal as per the itinerary.`,
        `Services of a Well experienced driver.`,
        `Driver Allowance, toll, parking fee, Night halt charges, inter- state permit and govt. applicable service tax.`,
        `Child below 4 years on Complimentary basis.`
      ],
      exclusions: [
        `Any other services or food which are not mentioned in the above "Includes" section.`,
        `Expense of personal nature such as tips, laundry, telephones, beverages etc.`,
        `Airfares and Train ticket charges if any`,
        `Any entrance Fees / Activities charges unless specific in inclusion.`
      ]
    }
  },
  {
    id: 2,
    place: 'Andaman Nicobar',
    image: POPULAR2,
    price: '28,799',
    description: `Experience Untouched beauty of nature,
      white sand beaches with blue water, lime stone caves, mangroves
      jungle, India’s only active volcano and underwater activities with
      us.`,
    duration: {
      day: 7,
      night: 6
    },
    details: {
      heading: 'Mesmerizing Andamans - 7D/6N',
      subHeading: '3N Portblair-2N Havelock-1N Neil Island',
      short_itinerary: [
        'Day - 1  Arrival at Port Blair (Sightseen  at Port Blair)',
        'Day - 2  Port Blair SS-  (Rose & North Bay Island)',
        'Day - 3  Port Blair to Havelock',
        'Day - 4  Day Trip To Elephant Beach ',
        'Day - 5  Havelock to Neil Island ',
        'Day - 6  Neil to Port Blair  ',
        'Day - 7  Departure for Home '
      ],
      inclusions: [
        `Accommodation inclusive of Buffet Breakfast`,
        `All transfers and sightseeing arrangements by Vehicle as per the Group Size.`,
        `Vehicle at your disposal as per the itinerary.`,
        `Services of a Well experienced driver.`,
        `Driver Allowance, toll, parking fee, Night halt charges, inter- state permit and govt. applicable service tax.`,
        `Entry permits/Entry tickets/Boat tickets`,
        `Private cruise Tickets (Port Blair- Havelock – Neil Island – Port Blair )`,
        `Child below 4 years on Complimentary basis.`
      ],
      exclusions: [
        `Any other services or food which are not mentioned in the above "Includes" section.`,
        `Expense of personal nature such as tips, laundry, telephones, beverages etc`,
        `Airfares and Train ticket charges if any`,
        `Any entrance Fees / Activities charges unless specific in inclusion.`
      ]
    }
  },
  {
    id: 3,
    place: 'Char Dham',
    image: POPULAR3,
    price: '36,499',
    description: `Char Dham yatra is not a journey but also a deeply
      transformative experience for devotees. Visit Kedarnath,
      Badrinath, Yamnotri and Gangotri in this journey.`,
    duration: {
      day: 10,
      night: 9
    },
    details: {
      heading: 'Char Dham 10D/9N',
      subHeading: '2N Barkot | 2N Uttarkashi | 3N Sitapur | 2N Joshimadh ',
      short_itinerary: [
        'Day - 1 	Haridwar to Barkot ',
        'Day - 2 	Barkot to Yamnotri',
        'Day - 3 	Barkot to Uttarkashi ',
        'Day - 4 	Uttarkashi to Gangotri',
        'Day - 5 	Uttarkashi to Sitapur ',
        'Day - 6 	Sitapur to Kedarnath',
        'Day - 7 	Sitapur sightseeing  ',
        'Day - 8  	Sitapur to Joshimadh',
        'Day - 9 	Joshimadh to Badrinath',
        'Day - 10 	Joshimadh to Haridwar'
      ],
      inclusions: [
        `Accommodation inclusive of Buffet Breakfast`,
        `All transfers and sightseeing arrangements by Vehicle as per the Group Size.`,
        `Vehicle at your disposal as per the itinerary.`,
        `Services of a Well experienced driver.`,
        `Driver Allowance, toll, parking fee, Night halt charges, inter- state permit and govt. applicable service tax.`,
        `Child below 4 years on Complimentary basis.`
      ],
      exclusions: [
        `Any other services or food which are not mentioned in the above "Includes" section.`,
        `Expense of personal nature such as tips, laundry, telephones, beverages etc`,
        `Airfares and Train ticket charges if any`,
        `Any entrance Fees / Activities charges unless specific in inclusion.`
      ]
    }
  },
  {
    id: 4,
    place: 'Goa',
    image: POPULAR4,
    price: '18,899',
    description: `Experience the vibrant night life style, serene
      beaches/lagoons, 300 years old Portuguese constructions along
      with numerous forts and Churches.`,
    duration: {
      day: 5,
      night: 4
    },
    details: {
      heading: 'Goa 5D/4N',
      subHeading: '2N North Goa | 2N South Goa',
      short_itinerary: [
        'Day - 1 Arrival at North Goa',
        'Day - 2 North Goa sightseeing',
        'Day - 3 North Goa to South Goa',
        'Day - 4 South Goa sightseeing',
        'Day - 5 Departure from South Goa'
      ],
      inclusions: [
        `Accommodation on twin sharing basis inclusive of Buffet Breakfast`,
        `Airport pickup & drop.`,
        `Child below 4 years on Complimentary basis.`
      ],
      exclusions: [
        `Any other services or food which are not mentioned in the above "Includes" section.`,
        `Expense of personal nature such as tips, laundry, telephones, beverages etc`,
        `Airfares and Train ticket charges if any`,
        `Any entrance Fees / Activities charges unless specific in inclusion.`
      ]
    }
  },
  {
    id: 5,
    place: 'Kashmir',
    image: POPULAR5,
    price: '28,499',
    description: `The land that many dreams to experience is India’s
      crown and Earth’s Paradise. Experience mighty ranges of
      Himalayas, Beautiful lakes, House boats and rich culture of
      Kashmir with us.`,
    duration: {
      day: 7,
      night: 6
    },
    details: {
      heading: 'Kashmir 6N/7D',
      subHeading: '',
      short_itinerary: [
        'Day 01 : Pick Up SRINAGAR LOCAL SIGHTSEEING / SHIKARA RIDE',
        'Day 02 : SRINAGAR – GULMARG (Approx 58 Kms/02 Hours Drive)',
        'Day 03 : GULMARG – PAHALGAM (Approx 150 Kms/04 Hours Drive)',
        'Day 04: PAHALGAM To PAHALGAM',
        'Day 05 : PAHALGAM SRINAGAR – (Approx 98 Kms/03 Hours Drive)',
        'Day 06 : SRINAGAR – SONAMARG – SRINAGAR (Approx 90 Kms /03 Hours)',
        'Day 07 : DEPARTURED'
      ],
      inclusions: [
        `Accommodation on twin sharing basis with Breakfast & Dinner at All Places`,
        `Shikara Ride`,
        `Shikara Crossing `,
        `Child below 8 yrs sharing parent’s bed`,
        `Extra adult will be provided roll away bed`,
        `Transport Pick up and Drop from Airport with all sightseeing by Vehicle as per itinerary`,
        `Driver allowance / toll / parking / road tax / fuel charges`,
        `Check in Check out 12.00 noon at all hotels`,
        `All Taxes With GST Included in below mentioned Rates. All applicable taxes service charges `
      ],
      exclusions: [
        `Any kind of Insurance`,
        `Air Fare / Train Fare and entry fees at sightseeing spots`,
        `Any additional meals other than mentioned in the inclusion `
      ]
    }
  },
  {
    id: 6,
    place: 'Leh Ladakh',
    image: POPULAR6,
    price: '29,899',
    description: `The untapped land’s calling to every adventurous
    soul, is this exciting Himalayan Traveller’s goal. Experience Best
    Road Trip with your friends and family here.`,
    duration: {
      day: 7,
      night: 6
    },
    details: {
      heading: 'Leh Ladakh Road Trip 6N/7D',
      subHeading: '',
      short_itinerary: [
        'Day 01 : ARRIVAL AT LEH  / LOCAL SIGHTSEEING',
        'Day 02 : FULL DAY TOUR TO INDUS VALLEY',
        'Day 03 : FULL DAY TOUR TO SHAAM VALLEY',
        'Day 04: LEH –-  NUBRA VALLEY ',
        'Day 05 : NUBRA VALLEY – PANGONG LAKE',
        'Day 06 : PANGONG LAKE - LEH',
        'Day 07 : DEPARTURED'
      ],
      inclusions: [
        `Accommodation on twin sharing basis with Breakfast & Dinner at All Places`,
        `Child below 8 yrs sharing parent’s bed`,
        `Extra adult will be provided roll away bed`,
        `Transport Pick up Drop from Airport with all sightseeing by Vehicle as per itinerary`,
        `Driver allowance / toll / parking / road tax / fuel charges`,
        `Check in Check out 12.00 noon at all hotels`,
        `All Taxes With GST Included in below mentioned Rates. All applicable taxes service charges `
      ],
      exclusions: [
        `Any other SS tours not mentioned in the programme.`,
        `Guide Services @ Rs. 2200/- Per day (English Speaking)`,
        `Any kind of Insurance`,
        `Permit pass in Nubra Valley Pangong Lake Rs.700 Per Person`,
        `Air Fare / Train Fare and entry fees at sightseeing spots`,
        `Any additional meals other than mentioned in the inclusion `,
        `Items of personal nature like porterage, tips, laundry, telephone calls, mineral water, Monastery Fee, Ladakh Environmental Fee, Oxygen Cylinder / Any joy ride etc.`
      ]
    }
  },
  {
    id: 7,
    place: 'North-East',
    image: POPULAR7,
    price: '38,899',
    description: `Seven sisters of different traits, breath taking in
    beauty, charming in grace: waterfall, wildlife, natural wonders;
    North-East is the place where falls thunder.`,
    duration: {
      day: 7,
      night: 6
    },
    details: {
      heading: 'North East 7D/6N',
      subHeading: '1N Guwahati | 1N Kaziranga | 4N Shillong',
      short_itinerary: [
        'Day - 1 Arrival at Guwahati',
        'Day - 2 Guwahati to Kaziranga',
        'Day - 3 Kaziranga to Shillong',
        'Day - 4 Shillong sightseeing',
        'Day - 5 Shillong to Cherrapunjee',
        'Day - 6 Shillong to Dawki',
        'Day - 7 Departure from Shillong'
      ],
      inclusions: [
        'Accommodation on twin sharing basis inclusive of Buffet Breakfast',
        'All transfers and sightseeing arrangements by AC Vehicle as per the Group Size.',
        'Vehicle at your disposal as per the itinerary.',
        'Services of a Well experienced driver.',
        'Driver Allowance, toll, parking fee, Night halt charges, inter- state permit and govt. applicable service tax.',
        'Child below 4 years on Complimentary basis.'
      ],
      exclusions: [
        `Any other services or food which are not mentioned in the above "Includes" section.`,
        `Expense of personal nature such as tips, laundry, telephones, beverages etc`,
        `Airfares and Train ticket charges if any`,
        `Any entrance Fees / Activities charges unless specific in inclusion.`
      ]
    }
  },
  {
    id: 8,
    place: 'Rajasthan',
    image: POPULAR8,
    price: '35,799',
    description: `The Empire of the Kings, reign of royal palaces and
    glorious forts, all reverberate the saga of victorious. Experience
    their tradition and culture with some amazing places with us.`,
    duration: {
      day: 7,
      night: 6
    },
    details: {
      heading: 'Rajasthan 7D/6N',
      subHeading: '2N Jaipur | 2N Jodhpur | 2N Udaipur',
      short_itinerary: [
        'Day - 1 Arrival at Jaipur',
        'Day - 2 Jaipur sightseeing',
        'Day - 3 Jaipur to Jodhpur',
        'Day - 4 Jodhpur sightseeing',
        'Day - 5 Jodhpur to Udaipur',
        'Day - 6 Udaipur sightseeing',
        'Day - 7 Departure from Udaipur'
      ],
      inclusions: [
        `Accommodation on twin sharing basis inclusive of Buffet Breakfast`,
        `All transfers and sightseeing arrangements by AC Vehicle as per the Group Size.`,
        `Vehicle at your disposal as per the itinerary.`,
        `Services of a Well experienced driver.`,
        `Driver Allowance, toll, parking fee, Night halt charges, inter- state permit and govt. applicable service tax.`,
        `Child below 4 years on Complimentary basis.`
      ],
      exclusions: [
        `Any other services or food which are not mentioned in the above "Includes" section.`,
        `Expense of personal nature such as tips, laundry, telephones, beverages etc`,
        `Airfares and Train ticket charges if any`,
        `Any entrance Fees / Activities charges unless specific in inclusion.`
      ]
    }
  },
  {
    id: 9,
    place: 'Varanasi',
    image: POPULAR9,
    price: '22,499',
    description: `The city of Ghats, known as Varanasi, Banaras Kashi.
    Vibrant, spiritual and stepped in mysticism, Varanasi offers an
    experience to satiate the soul.`,
    duration: {
      day: 6,
      night: 5
    },
    details: {
      heading: 'Varanasi 6D/5N',
      subHeading: '2N Varanasi | 2N Allahabad | 1N Faizabad',
      short_itinerary: [
        'Day - 1 Arrival at Varanasi',
        'Day - 2 Varanasi to Allahabad',
        'Day - 3 Allahabad to Ayodhya',
        'Day - 4 Ayodhya to Chitrakoot',
        'Day - 5 Allahabad to Varanasi',
        'Day - 6 Departure from Varanasi'
      ],
      inclusions: [
        `Accommodation on twin sharing basis inclusive of Buffet Breakfast`,
        `All transfers and sightseeing arrangements by AC Vehicle as per the Group Size.`,
        `Vehicle at your disposal as per the itinerary.`,
        `Services of a Well experienced driver.`,
        `Driver Allowance, toll, parking fee, Night halt charges, inter- state permit and govt. applicable service tax.`,
        `Child below 4 years on Complimentary basis.`
      ],
      exclusions: [
        `Any other services or food which are not mentioned in the above "Includes" section.`,
        `Expense of personal nature such as tips, laundry, telephones, beverages etc`,
        `Airfares and Train ticket charges if any`,
        `Any entrance Fees / Activities charges unless specific in inclusion.`
      ]
    }
  },
  {
    id: 10,
    place: 'Spiti valley',
    image: POPULAR10,
    price: '28,899',
    description: `Spiti - The Middle Land, Take a road trip to
    mountainous cold desert freckled with green patches over a dry
    weather - beaten face, fascinating valleys, windswept landscapes,
    ancient monasteries and quiet villages.`,
    duration: {
      day: 10,
      night: 9
    },
    details: {
      heading: 'Spiti Valley 10D/9N',
      subHeading:
        '1N Shimla | 1N Chitkul | 1N Kalpa | 1N Tabo | 3N Kaza  | 1N Chandrataal | 1N Manali',
      short_itinerary: [
        'Day 1 | Chandigarth  – Shimla / Narkanda',
        'Day 2 | Shimla / Narkanda – Sangla / Chitkul (Kinnaur Valley)',
        'Day 3 | Sangla – Chitkul – Sangla – Kalpa',
        'Day 4 | Kalpa – Nako – Nako Lake – Geyu Mummy – Tabo',
        'Day 5 | Tabo – Dhankar – Dhankar Lake – Pin Valley (Mud Village)',
        'Day 6 | Pin Valley – Kaza – Ki – Kibber – Gette – Tashigang – Kaza',
        'Day 7 | Kaza – Hikkim – Komik – Langza – Kaza / Losar',
        'Day 8 | Kaza / Losar – Kunzum Pass – Chandratal / Battal',
        'Day 9 | Battal / Chandratal – Kunzum Pass – Rohtang Pass – Manali',
        'Day 10 | Manali – Chandigarth '
      ],
      inclusions: [
        `Accommodation in Homestays and Hotels.`,
        `All Package included Breakfast and Dinner `,
        `All transfers and sightseeing by AC Vehicle as per the group size`,
        `Assistance at arrival and departure points.`,
        `Entry permits`,
        `All applicable taxes`
      ],
      exclusions: [
        `Any other services or food which are not mentioned in the above "Includes" section.`,
        `Expense of personal nature such as tips, Camera Fees, laundry, telephones, beverages etc`,
        `Any Activities charges unless specified`,
        `Airfares and Train ticket charges if any`
      ]
    }
  }
];

export const international_destinations_data = [
  {
    id: 1,
    place: 'Bali Indonesia',
    image: INTERNATIONAL1,
    description: `Enchanting natural ambience and the tranquillity
    of water in blue, Bali is an Island that will unleash the author in
    you.`
  },
  {
    id: 2,
    place: 'Dubai',
    image: INTERNATIONAL2,
    description: `This futuristic land which seems straight out of a magic
    lamp, is often seen as the first international trip option for many
    Indian travellers.`
  },
  {
    id: 3,
    place: 'Maldives',
    image: INTERNATIONAL3,
    description: `Tropical jewel of the Indian Ocean, twitching a kind of
    sorcery with your senses, teleporting you, straight to the paradise!`
  },
  {
    id: 4,
    place: 'Sri lanka',
    image: INTERNATIONAL4,
    description: `Establishing its existence with a surprising trail of more
    than 2,550 years of continuous written history, Sri Lanka is a
    marvel to behold! With strong traces of Indian influences, this
    home next to home, reflects an entire legacy of ancient history
    written on the same lines as India.`
  },
  {
    id: 5,
    place: 'Singapore',
    image: INTERNATIONAL5,
    description: `The homogenous cultural land from the future has little
    surprises to offer and morality to nurture.`
  },
  {
    id: 6,
    place: 'Vietnam',
    image: INTERNATIONAL6,
    description: `Vietnam travel packages from India let you travel to a
    land of gobsmacking natural beauty, cultural complexities, and
    several dynamic hill-tribe villages as well as megalopolises.`
  },
  {
    id: 7,
    place: 'Malaysia',
    image: INTERNATIONAL7,
    description: `Ancient ethnic multi cultures guarded by the dense
    ecosystems, endorsed in the rainbow life from the sky to sea!`
  },
  {
    id: 8,
    place: 'Bangkok Pattaya',
    image: INTERNATIONAL8,
    description: `Contrasting vibrant shades reflect diversity in a
    whole new picture! That is the city of Bangkok; as inviting as its
    delectable cuisine!`
  },
  {
    id: 9,
    place: 'Europe',
    image: INTERNATIONAL9,
    description: `The land of fantasy and the years of glory resonating
    melodious symphony in the cobblestoned streets.`
  }
];

export const unique_destinations_data = [
  {
    id: 1,
    place: 'Ooty-Bandipur-Wayanad',
    image: UNIQUE1,
    description: `Queen of Blue Mountains; Ooty,
    Oscillating between a rain-washed green and sun-soaked blanket
    of brown; Bandipur tropical forest and Wayanad; a less explored
    beautiful destination of North Kerala.`
  },
  {
    id: 2,
    place: 'Triupati Balaji-Mahabalipuram-Pondicherry',
    image: UNIQUE2,
    description: `Let the spirituality
      and divinity of Tirupati sweep over you as you fold your hands in
      prayer and bow your head in obeisance thence forward the French
      Riviera of the East awaits you!`
  },
  {
    id: 3,
    place: 'Nainital-Jim Corbett-Rishikesh',
    image: UNIQUE3,
    description: `Think of mighty Himalayas and
      Uttarakhand comes to your mind. Refresh you mind, body and soul
      in the lush scenery, try yoga at Ashrams in Rishikesh like ‘The
      Beatles’ or embark on trek or other adventures, you can enjoy all
      these in our package.`
  }
];

export const experience_data = [
  {
    id: 1,
    name: 'Rishita Patel',
    prefix: 'R',
    description: `It was really amazing experience with you guy's ❤️.  I recommend this "The gujarati traveller" to all my friends because they provide extra ordinary facilities and adventurous places. We were there in the forest, still we were provided by very good food 👌.  Must give a chance to them and they will never disappointing you.`
  },
  {
    id: 2,
    name: 'Meet Patel',
    prefix: 'M',
    description: `Best trip planner I have ever met. Very experienced and gave Perfect guidance about trip and also manage your trip in your budget. The best part of them is they were personally visited every places gathered each and every information about that place. Overall it is the perfect place for you to book your trip with this amazing people.`
  },
  {
    id: 3,
    name: 'Anil Patel',
    prefix: 'A',
    description: `We have experienced  well planned and organised Andaman and Nicobar trip with Gujarati traveller. Organiser Vidhiben is dedicated and always ready to solve any trip related issues... I personally suggest to trip with this if u want hasslefree experience`
  },
  {
    id: 4,
    name: 'Riddhi Patel',
    prefix: 'R',
    description: `The beautiful views this track holds is really amazing. We just fall in love with the peace it offers along with refreshing air and such a relaxing atmosphere. We wish to continue going for the track every year with The gujarati traveller ❤️only.`
  },
  {
    id: 5,
    name: 'Drashti Patel',
    prefix: 'D',
    description: `Very much experienced traveller, they will provide you funny, caring and loving company. the best thing about the company is they provide customised trip based on your budget, priorities and number of holidays you have. Also they will make you explore unexplored places of gujarat. Such a bliss to have travelled with you.`
  },
  {
    id: 6,
    name: 'Sumit Patel',
    prefix: 'S',
    description: `Experience traveller.she customised your trip in budget.she have a wide experience in travel plan and organisation best trip plan according to your convenience. I recommend everyone to visit her website and follow on Instagram about more info.thank you`
  },
  {
    id: 7,
    name: 'Anu chaudhary',
    prefix: 'A',
    description: `It was my First trekking experience , i was exited & nervous also. But the trekking experience is just wow!!!! Vidhi and Jay patel you are great leaders in this trekking. I really appreciate hospitality given here.. food and facilities also very good... Thank you for this amazing experience..`
  }

  // {
  //   id: 5,
  //   name: "Purvi Prajapati ",
  //   prefix: "P",
  //   description: `Amazing experience it was!!! Great leadership by Vidhi & jay Patel ...it was my first trekking camp & it was such a great adventure..
  //   At camp side & also in trekking the great supporters are Hirenbhai & all the team...well managed rooms ; food & all the facilities are totally apprrciatable...must visit with The Gujrati traveller`,
  // },

  // {
  //   id: 6,
  //   name: "Dr Dharmesh J Patel",
  //   prefix: "D",
  //   description: `Very nice cooperative tour organiser, really had great fun at Andoman. Nice itenary,hotels stay and transport. Thanks to theGujarati Traveller.`,
  // },

  // {
  //   id: 10,
  //   name: "Sumit Patel",
  //   prefix: "S",
  //   description: `Experience traveller.she customised your trip in budget.she have a wide experience in travel plan and organisation best trip plan according to your convenience. I recommend everyone to visit her website and follow on Instagram about more info.thank you`,
  // },
];

export const upcoming_data = [
  {
    id: 1,
    place: 'Andaman',
    image: UPCOMING1
  },
  {
    id: 2,
    place: 'Kerala',
    image: UPCOMING2
  },
  {
    id: 3,
    place: 'Ooty Bandipur Wayanad',
    image: UPCOMING3
  },
  {
    id: 4,
    place: 'Spiti Valley',
    image: UPCOMING4
  }
];

export const vlog_data = {
  image: VlogImage,
  title: 'A Paradise Escape: Exploring the Maldives',
  description: `Welcome to our Maldives adventure! In this vlog, we're taking you on a journey to
  one of the most stunning and exotic destinations on the planet - the Maldives.
  Nestled in the heart of the Indian Ocean, the Maldives is a tropical paradise known
  for its crystal-clear waters, overwater bungalows, vibrant marine life, and incredible
  natural beauty. Join us and discover the magic it has to offer.`,
  video_link: 'https://youtu.be/hyGrgrQ2TsY'
};

export const memories_data = {
  1: {
    id: 1,
    image: MEMORIES1
  },
  2: {
    id: 2,
    image: MEMORIES2
  },
  3: {
    id: 3,
    image: MEMORIES3
  },
  4: {
    id: 4,
    image: MEMORIES4
  },
  5: {
    id: 5,
    image: MEMORIES5
  },
  6: {
    id: 6,
    image: MEMORIES6
  }
};
