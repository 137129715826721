import React, { useEffect, useState } from 'react';
import { FaLongArrowAltDown, FaBars } from 'react-icons/fa';
import { hero_section_images, navItems } from '../../../utils/data';
import Slider from 'react-slick';
import Logo from '../../../assets/images/logo/logo_white.png';
import { MdClose } from 'react-icons/md';
// import { FaRegHandPointUp, FaRegHandPointLeft } from 'react-icons/fa';

const HeroSection = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const handleNavClick = (e, sectionId) => {
  //   e.preventDefault();
  //   // const section = document.querySelector(sectionId);
  //   // section.scrollIntoView({ behavior: "smooth" });
  //   if (sectionId === '#home') {
  //     window.scrollTo({ top: 0, behavior: 'smooth' });
  //   } else {
  //     const section = document.querySelector(sectionId);
  //     if (section) {
  //       section.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }
  // };
  const handleNavClick = (e, sectionId) => {
    e.preventDefault();
    // #who_we_are

    if (sectionId === '#home') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      const section = document.querySelector(sectionId);
      if (section) {
        if (sectionId === '#who_we_are') {
          // section.scrollIntoView({ behavior: 'smooth' });
          const targetPosition = section.getBoundingClientRect().top;
          window.scrollTo({ top: window.scrollY + targetPosition + 50, behavior: 'smooth' });
        } else {
          const targetPosition = section.getBoundingClientRect().top;
          window.scrollTo({ top: window.scrollY + targetPosition - 20, behavior: 'smooth' });
        }
      }
    }
    isMenuOpen &&
      setTimeout(() => {
        setIsMenuOpen(false);
      }, 2000);
  };

  useEffect(() => {
    const handleScroll = () => {
      // const scrollPosition = window.scrollY;
      // const triggerPosition = 720; // Adjust this value as needed

      // if (scrollPosition >= triggerPosition) {
      //   setIsSticky(true);
      // } else {
      //   setIsSticky(false);
      // }
      const heroSectionHeight = document.querySelector('#who_we_are').clientHeight - 60;
      if (window.scrollY >= heroSectionHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const hero_settings = {
    fade: true,
    autoplay: true,
    autoplaySpeed: 3500,
    dots: false,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <>
      <nav
        className={`flex flex-row justify-between items-center px-[3rem] sm:px-[5rem] z-50 w-full py-[5px]
          ${
            isSticky
              ? 'md:sticky md:top-[0px] md:bg-nav-color shadow-md transition-sticky'
              : 'absolute'
          }`}
      >
        <a href="https://www.youtube.com/@thegujaratitraveller" target="_blank" rel="noreferrer">
          <img src={Logo} alt="weblogo" width={50} height={90} className={`transition-sticky`} />
        </a>

        <div className="block md:hidden text-white">
          <FaBars onClick={toggleMenu} size={22} />
        </div>

        {/* nav bar list */}
        <ul className="hidden md:flex flex-row gap-5 lg:gap-8">
          {navItems.map((row, index) => {
            return (
              <li
                key={index + '_navitem'}
                className={`text-white active:text-color-green md:text-[14px] lg:text-[16px] xl:text-[18px] relative
                ${row.name.toLowerCase().includes('upcoming') && 'blink_me'}`}
              >
                {/* {row.name.toLowerCase().includes('upcoming') && (
                  <div className="absolute top-[24px] left-[-14px] blink_me origin-center rotate-45">
                    <FaRegHandPointUp className="" size={20} />
                  </div>
                )} */}
                <a
                  href={row.path}
                  onClick={(e) => handleNavClick(e, row.path)}
                  style={{ color: '#fff' }}
                >
                  {row.name}
                </a>
              </li>
            );
          })}
        </ul>

        {/* menu list */}
        <div
          className={`md:hidden ${
            isMenuOpen
              ? 'absolute top-0 right-0 bg-black bg-opacity-60 w-[100%] h-screen'
              : 'hidden'
          }`}
        >
          <div className="flex flex-col bg-[#fff] w-[70%] h-screen absolute top-0 right-0 animate-slideIn shadow-md">
            {isMenuOpen && (
              <div className="absolute top-8 right-8">
                <MdClose className="text-[32px]" onClick={toggleMenu} />
              </div>
            )}
            <ul
              className={`flex flex-col justify-start items-center gap-[30px] h-full mt-[120px] md:hidden`}
            >
              {navItems.map((row) => {
                return (
                  <li
                    key={row.path + 'menuItem'}
                    className={`relative text-[14px] md:text-[10px] lg:text-[16px] xl:text-[18px] w-[100%] 
                    ${isMenuOpen ? 'text-color-black' : 'text-white'}`}
                    style={{ borderBottom: '1px solid #ccc' }}
                  >
                    <a
                      href={`#${row.path}`}
                      onClick={(e) => {
                        handleNavClick(e, row.path);
                      }}
                      className={`block py-2 px-4 hover:bg-gray-700
                      ${row.name.toLowerCase().includes('upcoming') && 'blink_me'}`}
                      style={{ color: '#172432', fontSize: '18px' }}
                    >
                      {row.name}
                    </a>
                    {/* {row.name.toLowerCase().includes('upcoming') && (
                      <div className="blink_me">
                        <FaRegHandPointLeft size={20} />
                      </div>
                    )} */}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>

      {/* hero section start */}
      <div id="home" className="relative h-full w-full">
        {/* <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0 z-20" /> */}
        {/* <div className="absolute flex flex-row justify-between items-center px-[5rem] z-50 w-[100%] mt-[10px]"> */}

        {/* </div> */}

        <div className="absolute  top-1/2 left-1/2 z-10 w-full translate-x-[-50%] translate-y-[-50%] p-l-12 mt-[20px]">
          <h1
            className="text-white text-[36px] sm:text-[36px] md:text-[40px] lg:text-[52px] xl:text-[62px] 
            pl-10 pr-10 sm:pr-0 sm:pl-16 md:pl-20"
          >
            Start your unforgettable <br /> journey with us.
          </h1>
          {/* <p className="text-white xs:text-[12px] sm:text-[16px] md:text-[22px] lg:text-[28px] xl:text-[32px] pl-16 sm:pl-16 md:pl-20 mt-10">
            The best travel for your jouney begins now
          </p> */}

          <div
            className="bg-color-green text-white px-[15px] py-[10px] rounded-md w-max ml-10 sm:ml-16 md:ml-20 mt-10"
            onClick={(e) => handleNavClick(e, '#who_we_are')}
          >
            <a
              href="#who_we_are"
              className="flex flex-row items-center gap-[5px] text-[16px]"
              style={{ color: '#fff' }}
            >
              Get More <FaLongArrowAltDown />
            </a>
          </div>
        </div>

        <Slider {...hero_settings} className="h-full overflow-hidden relative">
          {hero_section_images.map((image, index) => (
            <img
              key={index + 'hero'}
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full max-w-full h-screen max-h-screen object-cover z-10"
            />
          ))}
        </Slider>
      </div>
      {/*  hero seciton end */}
    </>
  );
};

export default HeroSection;
